import cordialShoppeApi from "../../api/cordialShoppe";

import { toast, Zoom } from "react-toastify";


export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART_QTY = "UPDATE_CART_QTY";

export const addItemToCart = (item) => {
  return {
    type: ADD_TO_CART,
    payload: {
      item,
    },
  };
};
export const removeItemFromCart = (item) => {
  return {
    type: REMOVE_FROM_CART,
    payload: {
      item,
    },
  };
};
export const updateItemCartQty = (item) => {
  return {
    type: UPDATE_CART_QTY,
    payload: {
      item,
    },
  };
};

export const signup = (data) => async (dispatch) => {
  try {
    const response = await cordialShoppeApi.post("/account", data);
    console.log('response:', response);
    dispatch({ type: "SIGNUP", payload: response.data.Message });
  } catch (err) {
    dispatch({ type: "ADD_MESSAGE", payload: err.response.data.Message });
  }
};

export const signin = ({ Email, Password }, navigate) => async (dispatch) => {
  try {
    const response = await cordialShoppeApi.post("/account/login", {
      Email,
      Password,
    });
    // localStorage.setItem("token", response.data.Token);
    // localStorage.setItem("_xyz", response.data?.Role ? response.data.Role : "");
    dispatch({
      type: "SIGNIN",
      payload: {
        token: response.data.Token,
        role: response.data?.Role ? response.data.Role : "",
      }
    });
    navigate();
  } catch (err) {
    toast.error(err.response.data.Message, {
      containerId: "B",
      transition: Zoom,
    })
    dispatch({ type: "ADD_MESSAGE", payload: err.response.data.Message });
  }
};

export const signout = () => {
  localStorage.removeItem("token");
  toast.error("You've been signed out", {
    containerId: "B",
    transition: Zoom,
  })
  return { type: "SIGNOUT" };
};

export const changePassword = (
  { Email, NewPassword, ConfirmPassword },
  navigate
) => async (dispatch) => {
  cordialShoppeApi
    .post("/changepassword", {
      Email,
      NewPassword,
      ConfirmPassword,
    })
    .then((res) => {
      console.log('yolo')
      toast.success(res.data.Message, {
        containerId: "B",
        transition: Zoom,
      })
      navigate();
    })
    .catch((err) => {
      console.log("ERROR:", err.response.data.Message);
      toast.error(err.response.data.Message, {
        containerId: "B",
        transition: Zoom,
      })
      dispatch({ type: "ADD_MESSAGE", payload: err.response.data.Message });
    });
};

export const clearAuthMessage = () => {
  return { type: "CLEAR_AUTH_MESSAGE" };
};
