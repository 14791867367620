import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  FormText,
  Col,
} from 'reactstrap';
import MultiDropdownNavbar from '../../components/Navbars/MultiDropdownNavbar';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { signin, clearAuthMessage } from '../../Reducers/Actions/userActions';
import { toast, Zoom } from "react-toastify";

const Signin = ({ user, signin, clearAuthMessage }) => {
  const { register, handleSubmit } = useForm();
  const {message, setMessage} = useState('');
  const history = useHistory();

  useEffect(() => {
    clearAuthMessage();
  }, [])

  const signIn = (data) => signin(data, () => history.push('/'));
    

  return (
    <div style={styles.flexContainer}>
      <MultiDropdownNavbar />
      <Container style={styles.formContainer}>
        <h1 style={styles.formTitle}>Sign In</h1>
        <Form style={styles.form} onSubmit={handleSubmit(signIn)}>
          <Col>
            <FormGroup>
              <Label for="signin-email">Email</Label>
              <Input
                type="email"
                name="Email"
                id="signin-email"
                placeholder="name@email.com"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="signin-password">Password</Label>
              <Input
                type="password"
                name="Password"
                id="signin-password"
                placeholder="********"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <a href="/forgotpw" style={{fontWeight:'bold'}}>Forgot password?</a><br/><br/>
          </Col>
          <Button type="submit" className="btn-fill" color="primary">
            Submit
          </Button>
          {message? (
            <>
            {console.log(user.authMessage)}
            {/* <p style={{ fontWeight: 'bold' }}>{user.authMessage}</p> */}
            {}
            </>
          ) : null}
          <FormText>
            If you don't have an account create one{' '}
            <a style={{ fontWeight: 'bold' }} href="signup">
              here
            </a>
          </FormText>
        </Form>
      </Container>
    </div>
  );
};

const styles = {
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(' + require('../../assets/img/bottle.png') + ')'
  },
  formContainer: {
    paddingBottom: '30px',
    borderRadius: '10px',
    maxWidth: '700px',
    backgroundColor: '#FFFFFF',
  },
  formTitle: {
    marginBottom: '30px',
  },
  form: {},
};

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
};

export default connect(mapStateToProps, { signin, clearAuthMessage })(Signin);
