import React from "react";
// plugin that creates slider
// import Slider from "nouislider";

import {
  Button,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";
import axios from "axios";

let {contact_env} = require('../../env');
let contact_url = contact_env.url;

const submitForm = () => {
  document.getElementById("submit_contact_button").style.display = "none";
  document.getElementById("sending_contact_button").style.display = "block";

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      contact_url,
      {
        Name: document.getElementById("Name").value,
        Email: document.getElementById("Email").value,
        Service: "Cordial Shoppe",
        Mobile: document.getElementById("Mobile").value,
        Message: document.getElementById("Message").value,
      },
      headers
    )
    .then((res) => {
      // document.getElementById("success_alert").style.display = "block";
      // document.getElementById("error_alert").style.display = "none";

      toast.success(`Message successfully sent. Thank you!`, {
        containerId: "B",
        transition: Zoom,
      });

      document.getElementById("submit_contact_button").style.display = "block";
      document.getElementById("sending_contact_button").style.display = "none";
    })
    .catch((err) => {
      // console.error(err);
      // document.getElementById("success_alert").style.display = "none";
      // document.getElementById("error_alert").style.display = "block";

      toast.error(`Contact error, we apologize, please call us at 313.291.1561!`, {
        containerId: "B",
        transition: Zoom,
      });

      document.getElementById("submit_contact_button").style.display = "block";
      document.getElementById("sending_contact_button").style.display = "none";
    });
};

function ContactUs() {
  return (
    <>
      <div className="section landing-section" id="contact-section">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/store_front.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Contact Us
                  </CardTitle>

                  <Row>
                    <Col xs="3" style={{ textAlign: "center" }}>
                      <a
                        class="btn-just-icon mr-1 btn btn-facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/cordialshoppe/"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </Col>
                    <Col xs="3" style={{ textAlign: "center" }}>
                      <a
                        class="btn-just-icon btn btn-instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/thecordialshoppe/"
                      >
                        <i class="fa fa-instagram"></i>
                      </a>
                    </Col>
                    <Col xs="3" style={{ textAlign: "center" }}>
                      <a
                        class="btn-just-icon mr-1 btn btn-yelp"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.yelp.com/biz/the-cordial-shoppe-taylor"
                      >
                        <i class="fa fa-yelp"></i>
                      </a>
                    </Col>
                    <Col xs="3" style={{ textAlign: "center" }}>
                      <a
                        class="btn-just-icon mr-1 btn btn-youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://youtu.be/7LARlhI9G94"
                      >
                        <i class="fa fa-youtube"></i>{" "}
                      </a>
                    </Col>
                  </Row>

                  <Row color="black">
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="">
                            <h4 className="">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://goo.gl/maps/uigA3hh8U952VYDg8"
                              >
                                9045 Telegraph Rd
                              </a>
                            </h4>
                            <p>Taylor MI, 48180</p>
                          </div>
                        </div>

                        {/* <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-watch-time" />
                          </div>
                          <div className="">
                            <h4 className="">Hours of Operation</h4>
                            <div>
                              <br />
                              Mon - Wed 9:00 AM to 11:00 PM
                              <br />
                              Thur - Sat 9:00 AM to 12:00AM
                              <br />
                              Sunday 11:00 AM to 10:00 PM
                              <br />
                            </div>
                          </div>
                        </div> */}

                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-mobile" />
                          </div>
                          <div className="">
                            <h4 className="">The Cordial Shoppe</h4>
                            <p>
                              <br />
                              <a
                                className="btn-link"
                                color="danger"
                                href="tel:+13132911561"
                              >
                                (313) 291-1561
                              </a>
                              <br /><br />
                              - Press 1 to place an order or to speak to a kitchen team member
                              <br /><br />
                              - Press 2 to speak to a store associate on product availability or 
                              with any other questions
                            </p>
                          </div>
                        </div>

                        {/* <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-chart-pie-36" />
                          </div>
                          <div className="descrition">
                            <h4 className="">Stacks & Cordials</h4>
                            <p>
                              <br />
                              <a
                                className="btn-link"
                                color="danger"
                                href="tel:+13132911550"
                              >
                                (313) 291-1550
                              </a>
                            </p>
                          </div>
                        </div> */}
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Form id="contact-form" method="post" role="form">
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <FormGroup className="label-floating">
                                <label className="control-label">Name</label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  id="Name"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Email address
                            </label>
                            <Input
                              name="email"
                              placeholder="Email"
                              id="Email"
                              type="email"
                            />
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">Mobile</label>
                            <Input
                              name="Mobile"
                              id="Mobile"
                              placeholder="313.291.1561"
                              type="phone"
                            />
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Your message
                            </label>
                            <Input
                              name="message"
                              id="Message"
                              placeholder="Message"
                              type="textarea"
                              rows="6"
                            />
                          </FormGroup>
                          {/* <Row>
                            <Col md="12">
                              <FormGroup check>
                                <Label check>
                                  <Input defaultValue="" type="checkbox" />
                                  I'm not a robot !{" "}
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <br/> */}
                          <Row>
                            <Col md="12">
                              <Button
                                className="btn-fill"
                                color="danger"
                                size="lg"
                                id="submit_contact_button"
                                onClick={() => submitForm()}
                              >
                                <i className="nc-icon nc-email-85 mr-1" />
                                Send Message
                              </Button>
                              <Button
                                className="btn-round"
                                color="default"
                                style={{ display: "none" }}
                                id="sending_contact_button"
                              >
                                <div className="uil-reload-css reload-small mr-1">
                                  <div />
                                </div>
                                Sending...
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <br />

            {/* <Row id="success_alert" style={{ display: "none" }}>
              <Col>
                <div class="alert alert-success fade show" role="alert">
                  <div class="container">
                    <span>Message sent!</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row id="error_alert" style={{ display: "none" }}>
              <Col>
                <div
                  class="alert-with-icon alert alert-danger fade show"
                  role="alert"
                >
                  <div class="container">
                    <div class="alert-wrapper">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <i class="nc-icon nc-simple-remove"></i>
                      </button>
                      <div class="message">
                        <i class="nc-icon nc-bell-55"></i> Sorry there was an
                        error, please call us directly. (313.291.1561)
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
      </div>
    </>
  );
}

export default ContactUs;
