import React from "react";
//import Select from "react-select";

// reactstrap components
import { Button } from "reactstrap";

function MathTopping(props) {
  let options = [
    {
      value: "0",
      label: "No " + props.data.name,
    },
    {
      value: "1",
      label: "Light " + props.data.name,
    },
    {
      value: "2",
      label: "Half " + props.data.name,
    },
    {
      value: "3",
      label: "Regular " + props.data.name,
    },
    {
      value: "4",
      label: "Extra " + props.data.name,
    },
  ];

  const [optionSelect, setOptionSelect] = React.useState(props.data.value);

  if (props.data.value !== 0 && typeof props.data.value === "number") {
    console.log("props.data.value:", props.data.value);
    props.data.setValue(options[props.data.value].label);
  }

  const addTop = async () => {
    let inc = optionSelect + 1;
    if (inc >= options.length) {
      inc--;
    }
    setOptionSelect(inc);
    props.data.setValue(options[inc].label);
  };
  const minTop = async () => {
    let inc = optionSelect - 1;
    if (inc < 0) {
      inc = 0;
    }
    setOptionSelect(inc);
    props.data.setValue(options[inc].label);
    //props.data.setValue({...topping, props.data.name: topping[`${props.data.name}`] + inc});
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("product-page");
    return function cleanup() {
      document.body.classList.remove("product-page");
    };
  });

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20% 60% 20%",
          gridGap: "10px",
          paddingBottom: "2px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Button
            className="btn-just-icon btn-round btn-tooltip"
            color="danger"
            id={"tooltip" + props.data.name}
            outline
            value={optionSelect}
            onClick={() => minTop()}
          >
            <i className="fa fa-minus" />
          </Button>
        </div>
        <div style={{ textAlign: "center", gridColumns: "2 / 3" }}>
          <label>{options[optionSelect].label}</label>
        </div>
        <div style={{ textAlign: "center", justifyContent: "right" }}>
          <Button
            className="btn-just-icon btn-round btn-tooltip"
            color="success"
            id={"tooltip" + props.data.name}
            outline
            //onclick={addTop()}
            value={optionSelect}
            onClick={() => addTop()}
          >
            <i className="fa fa-plus" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default MathTopping;
