import React, { useEffect, useState } from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
    Form,
    Container,
    Row,
    Col,
    Input as Inp,
    Table,
    Label,
} from "reactstrap";

import { useHistory } from "react-router-dom";

// core components
import SettingsHeader from "components/Headers/SettingsHeader.js";
import DashNavbar from "components/Navbars/DashNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import Input from "@mui/material/Input";

// Redux
import { useDispatch } from "react-redux";
import { setHours } from "./../../Reducers/Actions/SettingsActions";

import cordialShoppeApi from "../../api/cordialShoppe";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const packageJson = require("../../../package.json");

export default function Dash() {
    const [printing, setPrinting] = useState(false);
    const [onlineOrders, setOnlineOrders] = useState(false);

    const dispatch = useDispatch();
    const settings = useSelector((state) => state.SettingsReducer);
    const user = useSelector((state) => state.AccountReducer);

    // const [tooltipOpen, setTooltipOpen] = useState(false);

    const history = useHistory();

    const [ready, setReady] = useState(true);

    const [sundayOpen, setSundayOpen] = useState("00:00");
    const [sundayClose, setSundayClose] = useState("00:00");
    const [mondayOpen, setMondayOpen] = useState("00:00");
    const [mondayClose, setMondayClose] = useState("00:00");
    const [tuesdayOpen, setTuesdayOpen] = useState("00:00");
    const [tuesdayClose, setTuesdayClose] = useState("00:00");
    const [wednesdayOpen, setWednesdayOpen] = useState("00:00");
    const [wednesdayClose, setWednesdayClose] = useState("00:00");
    const [thursdayOpen, setThursdayOpen] = useState("00:00");
    const [thursdayClose, setThursdayClose] = useState("00:00");
    const [fridayOpen, setFridayOpen] = useState("00:00");
    const [fridayClose, setFridayClose] = useState("00:00");
    const [saturdayOpen, setSaturdayOpen] = useState("00:00");
    const [saturdayClose, setSaturdayClose] = useState("00:00");

    useEffect(() => {
        console.log("uPdating states", settings.Hours);
        setMondayOpen(settings.Hours.Monday.Open);
        setMondayClose(settings.Hours.Monday.Close);
        setTuesdayOpen(settings.Hours.Tuesday.Open);
        setTuesdayClose(settings.Hours.Tuesday.Close);
        setWednesdayOpen(settings.Hours.Wednesday.Open);
        setWednesdayClose(settings.Hours.Wednesday.Close);
        setThursdayOpen(settings.Hours.Thursday.Open);
        setThursdayClose(settings.Hours.Thursday.Close);
        setFridayOpen(settings.Hours.Friday.Open);
        setFridayClose(settings.Hours.Friday.Close);
        setSaturdayOpen(settings.Hours.Saturday.Open);
        setSaturdayClose(settings.Hours.Saturday.Close);
        setSundayOpen(settings.Hours.Sunday.Open);
        setSundayClose(settings.Hours.Sunday.Close);
    }, [settings.Hours]);

    const sendHoursToDB = async () => {
        setReady(false);

        const updateHours = {
            Monday: {
                Open: mondayOpen,
                Close: mondayClose,
            },
            Tuesday: {
                Open: tuesdayOpen,
                Close: tuesdayClose,
            },
            Wednesday: {
                Open: wednesdayOpen,
                Close: wednesdayClose,
            },
            Thursday: {
                Open: thursdayOpen,
                Close: thursdayClose,
            },
            Friday: {
                Open: fridayOpen,
                Close: fridayClose,
            },
            Saturday: {
                Open: saturdayOpen,
                Close: saturdayClose,
            },
            Sunday: {
                Open: sundayOpen,
                Close: sundayClose,
            },
        };

        await dispatch(setHours(updateHours));
        let lbl = document.getElementById("oohlbl");
        lbl.style.color = "green";
        lbl.innerText = "";
        lbl.innerText = "Times are Saved!";
        setReady(true);
    };

    const getSettingsSetup = async () => {
        try {
            let response = await cordialShoppeApi.get("/settings");
            for (let setting of response.data) {
                if (setting.SettingID === "OnlineOrders") {
                    setOnlineOrders(setting.Value);
                    let onlineOrderSwitch =
                        document.getElementById("onlineOrderSwitch");
                    let onlineOrderLoading =
                        document.getElementById("onlineOrderLoading");
                    onlineOrderSwitch.style.display = "block";
                    onlineOrderLoading.style.display = "none";
                } else if (setting.SettingID === "PrintOrders") {
                    setPrinting(setting.Value);
                    let printOrderSwitch =
                        document.getElementById("printingSwitch");
                    let printOrderLoading =
                        document.getElementById("printingLoading");
                    printOrderSwitch.style.display = "block";
                    printOrderLoading.style.display = "none";
                } else if (setting.SettingID === "CMS1") {
                    let cms1Input = document.getElementById("CMS1");
                    cms1Input.value = setting.Value;
                } else if (setting.SettingID === "CMSHOURS1") {
                    let cmshours1Input = document.getElementById("CMSHOURS1");
                    cmshours1Input.value = setting.Value;
                } else if (setting.SettingID === "CMSHOURS2") {
                    let cmshours2Input = document.getElementById("CMSHOURS2");
                    cmshours2Input.value = setting.Value;
                } else if (setting.SettingID === "CMSHOURS3") {
                    let cmshours3Input = document.getElementById("CMSHOURS3");
                    cmshours3Input.value = setting.Value;
                } else if (setting.SettingID === "CMSWAITTIME") {
                    let cmswaittimeInput =
                        document.getElementById("CMSWAITTIME");
                    cmswaittimeInput.value = setting.Value;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        console.log("wed", wednesdayOpen);
    }, [wednesdayOpen]);

    useEffect(() => {
        console.log(settings, settings.Hours, settings.Hours.Monday.Open);
        if (user?.role !== "Engineer" && user?.role !== "Admin") {
            history.push("/");
        }
    }, []);

    const updateCMS1 = async (e) => {
        let cms1Label = document.getElementById("CMS1Label");
        cms1Label.style.color = "red";
        try {
            await cordialShoppeApi.post("/setting", {
                SettingID: "CMS1",
                Value: e.target.value,
            });
            cms1Label.style.color = "green";
        } catch (err) {
            console.log(err);
        }
    };

    const updateCMSHours1 = async (e) => {
        let cmshours1Label = document.getElementById("CMSHours1Label");
        cmshours1Label.style.color = "red";
        try {
            await cordialShoppeApi.post("/setting", {
                SettingID: "CMSHOURS1",
                Value: e.target.value,
            });
            cmshours1Label.style.color = "green";
        } catch (err) {
            console.log(err);
        }
    };
    const updateCMSHours2 = async (e) => {
        let cmshours2Label = document.getElementById("CMSHours2Label");
        cmshours2Label.style.color = "red";
        console.log("fhjdbsbfjkbdsjkbfdjk");
        try {
            await cordialShoppeApi.post("/setting", {
                SettingID: "CMSHOURS2",
                Value: e.target.value,
            });
            cmshours2Label.style.color = "green";
        } catch (err) {
            console.log(err);
        }
    };
    const updateCMSHours3 = async (e) => {
        let cmshours3Label = document.getElementById("CMSHours3Label");
        cmshours3Label.style.color = "red";
        console.log("34354565767576768");
        try {
            await cordialShoppeApi.post("/setting", {
                SettingID: "CMSHOURS3",
                Value: e.target.value,
            });
            cmshours3Label.style.color = "green";
        } catch (err) {
            console.log(err);
        }
    };
    const updatePrinting = async () => {
        try {
            console.log("updatePrinting!");
            let printingSwitch = document.getElementById("printingSwitch");
            let printingLoading = document.getElementById("printingLoading");
            printingSwitch.style.display = "none";
            printingLoading.style.display = "block";

            await cordialShoppeApi
                .post("/setting", {
                    SettingID: "PrintOrders",
                    Value: !printing,
                })
                .then(() => {
                    printingSwitch.style.display = "block";
                    printingLoading.style.display = "none";
                });
            setPrinting(!printing);
        } catch (err) {
            console.log(err);
        }
    };
    const updateOnlineOrders = async () => {
        try {
            let onlineOrderSwitch =
                document.getElementById("onlineOrderSwitch");
            let onlineOrderLoading =
                document.getElementById("onlineOrderLoading");
            onlineOrderSwitch.style.display = "none";
            onlineOrderLoading.style.display = "block";

            await cordialShoppeApi
                .post("/setting", {
                    SettingID: "OnlineOrders",
                    Value: !onlineOrders,
                })
                .then(() => {
                    onlineOrderSwitch.style.display = "block";
                    onlineOrderLoading.style.display = "none";
                });
            setOnlineOrders(!onlineOrders);
        } catch (err) {
            console.log(err);
        }
    };
    const updateWaitTime = async (e) => {
        let cmsWaitTimeLabel = document.getElementById("CMSWaitTimeLabel");
        cmsWaitTimeLabel.style.color = "red";
        try {
            await cordialShoppeApi.post("/setting", {
                SettingID: "CMSWAITTIME",
                Value: e.target.value,
            });
            cmsWaitTimeLabel.style.color = "green";
        } catch (err) {
            console.log(err);
        }
    };

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("settings-page");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        getSettingsSetup();
        return function cleanup() {
            document.body.classList.remove("settings-page");
        };
    }, []);

    return (
        <>
            <DashNavbar />
            <div className="wrapper">
                <SettingsHeader />
                <div className="profile-content section">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <Form className="settings-form">
                                    <Row>
                                        <Col md="6" sm="6"></Col>
                                        <Col md="6" sm="6"></Col>
                                    </Row>
                                    <h3 id="DOLO">Admin Dashboard</h3>
                                    <ul className="notifications">
                                        <li className="notification-item">
                                            <Row>
                                                <Col md="6" sm="6">
                                                    Online orders
                                                </Col>
                                                <Col md="6" sm="6">
                                                    <span
                                                        id="onlineOrderSwitch"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <Switch
                                                            onColor="info"
                                                            offColor="info"
                                                            onChange={
                                                                updateOnlineOrders
                                                            }
                                                            value={onlineOrders}
                                                        />
                                                    </span>
                                                    <div
                                                        id="onlineOrderLoading"
                                                        className="uil-reload-css reload-small mr-1"
                                                    >
                                                        <div />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </li>
                                        <li className="notification-item">
                                            <Row>
                                                <Col md="6" sm="6">
                                                    Print Orders
                                                </Col>
                                                <Col md="6" sm="6">
                                                    <span
                                                        id="printingSwitch"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <Switch
                                                            onColor="info"
                                                            offColor="info"
                                                            onChange={
                                                                updatePrinting
                                                            }
                                                            value={printing}
                                                        />
                                                    </span>
                                                    <div
                                                        id="printingLoading"
                                                        className="uil-reload-css reload-small mr-1"
                                                    >
                                                        <div />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </li>
                                        <li className="notification-item">
                                            <Row>
                                                <Col md="6" sm="6">
                                                    Version
                                                </Col>
                                                <Col
                                                    md="6"
                                                    sm="6"
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    v{packageJson.version}
                                                </Col>
                                            </Row>
                                        </li>
                                    </ul>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="10">
                                <Form className="settings-form">
                                    <h3>CMS Settings</h3>
                                    <ul className="notifications">
                                        <li className="notification-item">
                                            <Row>
                                                <Col
                                                    md="3"
                                                    sm="3"
                                                    id="CMS1Label"
                                                >
                                                    CMS Box #1
                                                </Col>
                                                <Col md="9" sm="9">
                                                    <Inp
                                                        id="CMS1"
                                                        onBlur={updateCMS1}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    md="3"
                                                    sm="3"
                                                    id="CMSHours1Label"
                                                >
                                                    Hours Line #1
                                                </Col>
                                                <Col md="9" sm="9">
                                                    <Inp
                                                        id="CMSHOURS1"
                                                        onBlur={updateCMSHours1}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    md="3"
                                                    sm="3"
                                                    id="CMSHours2Label"
                                                >
                                                    Hours Line #2
                                                </Col>
                                                <Col md="9" sm="9">
                                                    <Inp
                                                        id="CMSHOURS2"
                                                        onBlur={updateCMSHours2}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    md="3"
                                                    sm="3"
                                                    id="CMSHours3Label"
                                                >
                                                    Hours Line #3
                                                </Col>
                                                <Col md="9" sm="9">
                                                    <Inp
                                                        id="CMSHOURS3"
                                                        onBlur={updateCMSHours3}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    md="3"
                                                    sm="3"
                                                    id="CMSWaitTimeLabel"
                                                >
                                                    Order Wait Time (mins)
                                                </Col>
                                                <Col md="9" sm="9">
                                                    <Inp
                                                        id="CMSWAITTIME"
                                                        onBlur={updateWaitTime}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row
                                                style={{
                                                    display: "flex",
                                                    alignItem: "center",
                                                    justifyContent: "center",
                                                    marginTop: "1rem",
                                                }}
                                            >
                                                <Col lg="17" md="10" sm="8">
                                                    <br />
                                                    <br />
                                                    <CardSubtitle tag="h4">
                                                        Online Ordering Hours
                                                    </CardSubtitle>
                                                    <Table striped>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        fontWeight:
                                                                            "700",
                                                                    }}
                                                                >
                                                                    Day
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        fontWeight:
                                                                            "700",
                                                                    }}
                                                                >
                                                                    Open
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        fontWeight:
                                                                            "700",
                                                                    }}
                                                                >
                                                                    Close
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Sunday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="sundayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            sundayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSundayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="sundayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="sundayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            sundayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSundayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="sundayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Monday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="mondayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            mondayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setMondayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="mondayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="mondayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            mondayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setMondayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="mondayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Tuesday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="tuesdayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            tuesdayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setTuesdayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="tuesdayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="tuesdayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            tuesdayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setTuesdayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="tuesdayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Wednesday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="wednesdayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            wednesdayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setWednesdayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="wednesdayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="wednesdayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            wednesdayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setWednesdayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="wednesdayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Thursday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="thursdayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            thursdayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setThursdayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="thursdayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="thursdayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            thursdayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setThursdayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="thursdayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Friday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="fridayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            fridayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setFridayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="fridayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="fridayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            fridayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setFridayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="fridayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Saturday
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="saturdayOpen"></Label>
                                                                    <Input
                                                                        value={
                                                                            saturdayOpen ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSaturdayOpen(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="saturdayOpen"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    <Label for="saturdayClose"></Label>
                                                                    <Input
                                                                        value={
                                                                            saturdayClose ||
                                                                            "00:00"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSaturdayClose(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        type="time"
                                                                        name="time"
                                                                        id="saturdayClose"
                                                                        placeholder="time placeholder"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItem: "center",
                                                            justifyContent:
                                                                "center",
                                                            marginTop: "1rem",
                                                        }}
                                                    >
                                                        <Button
                                                            class="btn btn-success"
                                                            onClick={() =>
                                                                sendHoursToDB()
                                                            }
                                                        >
                                                            <i className="fa fa-clock-o" />
                                                            {ready
                                                                ? "Save Times"
                                                                : "Saving..."}
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItem: "center",
                                                            justifyContent:
                                                                "center",
                                                            marginTop: "1rem",
                                                        }}
                                                    >
                                                        <div id="oohlbl"></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </li>
                                    </ul>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <FooterWhite />
        </>
    );
}

// const mapStateToProps = (state) => {
//     return { user: state.AccountReducer, settings: state.SettingsReducer };
// };

// export default connect(mapStateToProps, {
//     setHours,
//     setChosenDay,
//     setTime,
//     setTime2,
//     requestSent,
// })(Dash);
