import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Form, Input, Container, FormGroup } from "reactstrap";
import MultiDropdownNavbar from "../../components/Navbars/MultiDropdownNavbar.js";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { signup, clearAuthMessage } from "../../Reducers/Actions/userActions";
import StateSelect from "../../components/StateSelect";

function Signup({ user, signup, clearAuthMessage }) {
  const { register, handleSubmit, errors } = useForm();
  const [cell, setCell] = useState();
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  });

  useEffect(() => {
    clearAuthMessage();
  }, []);

  const signUp = (data) => signup({ ...data, Cell: cell.slice(1) });

  return (
    <>
      <MultiDropdownNavbar />
      <div style={styles.parentContainer} className="wrapper">
        <Container style={styles.formContainer}>
          <h1 style={{ marginBottom: "30px" }}>Sign Up</h1>
          <Form onSubmit={handleSubmit(signUp)}>
            {/* FIRST & LAST NAME */}
            <div className="form-row">
              <div className="col">
                <FormGroup>
                  <label for="first-name">First Name</label>
                  <Input
                    id="first-name"
                    name="FirstName"
                    type="text"
                    placeholder="John"
                    innerRef={register({ required: true })}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <label for="last-name">Last Name</label>
                  <Input
                    id="last-name"
                    name="LastName"
                    type="text"
                    placeholder="Smith"
                    innerRef={register({ required: true })}
                  ></Input>
                </FormGroup>
              </div>
            </div>
            {/* EMAIL */}
            <FormGroup>
              <label for="email">Email</label>
              <Input
                id="email"
                name="Email"
                type="email"
                placeholder="example@gmail.com"
                innerRef={register({ required: true })}
              ></Input>
            </FormGroup>
            {/* PASSWORD */}
            <FormGroup>
              <label for="password">Password</label>
              <Input
                id="password"
                name="Password"
                type="password"
                placeholder="Password"
                innerRef={register({ required: true })}
              ></Input>
            </FormGroup>
            {/* ADDRESS */}
            <FormGroup>
              <label for="address">Address</label>
              <Input
                id="address"
                name="Address"
                type="text"
                placeholder="123 Main St"
                innerRef={register({ required: true })}
              ></Input>
            </FormGroup>
            <FormGroup>
              <label for="address-two">Address 2 (Optional)</label>
              <Input
                id="address-two"
                name="AddressTwo"
                type="text"
                placeholder="123 Secondary St"
                innerRef={register()}
              ></Input>
            </FormGroup>
            {/* CITY/STATE/ZIP */}
            <div className="form-row">
              <div className="col">
                <FormGroup>
                  <label for="city">City</label>
                  <Input
                    id="city"
                    name="City"
                    type="text"
                    placeholder="Detroit"
                    innerRef={register({ required: true })}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <label for="state">State</label>
                  <Input
                    id="state"
                    name="State"
                    type="select"
                    innerRef={register({ required: true })}
                  >
                    <StateSelect />
                  </Input>
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup className="col-xs-2">
                  <label for="zip">Zip</label>
                  <Input
                    id="zip"
                    name="Zipcode"
                    type="text"
                    innerRef={register({ required: true })}
                  ></Input>
                </FormGroup>
              </div>
            </div>
            {/* CELL */}
            <div id="cell-container" className="form-row">
              <div className="col">
                <FormGroup>
                  <label for="cell">Cell</label>
                  <PhoneInput  
                    id="cell"
                    name="Cell"
                    placeholder="13131234567"
                    value={cell}
                    onChange={setCell}
                  />
                </FormGroup>
              </div>
              <div className="col"></div>
            </div>
            <Button color="primary" type="submit">
              Submit
            </Button>
            {user.authMessage ? <br /> : null}
            {user.authMessage || null}
          </Form>
        </Container>
      </div>
    </>
  );
}

const styles = {
  parentContainer: {
    padding: "120px 10px 10px",
    minHeight: "100vh",
    background:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(" +
      require("../../assets/img/bottle.png") +
      ")",
  },
  formContainer: {
    maxWidth: "700px",
    backgroundColor: "#FFF",
    padding: "10px 10px 30px",
    borderRadius: "10px",
  },
};

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
};

export default connect(mapStateToProps, { signup, clearAuthMessage })(Signup);
