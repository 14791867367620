export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SIGNUP':
      return { ...state, authMessage: payload };
    case 'SIGNIN':
      return { ...state, authMessage: '', token: payload.token, role: payload.role };
    case 'SIGNOUT':
      return { token: null, authMessage: '' };
    case 'ADD_MESSAGE':
      return { ...state, authMessage: payload };
    case 'CLEAR_AUTH_MESSAGE':
      return { ...state, authMessage: '' };
    default:
      return state;
  }
};
