import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const LoadingSpinner = ({
  loading,
  color,
  setLoading,
  size,
  cssOverride = {},
}) => (
  <div className="sweet-loading">
    {/* <button onClick={() => setLoading(!loading)}>
      <i class="fas fa-times"></i>
    </button> */}
    <BeatLoader color={color} loading={loading} css={cssOverride} size={size} />
  </div>
);

export default LoadingSpinner;
