import React, { useState, useEffect } from "react";

import dayjs from 'dayjs';

// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

import {
    Card, CardText, CardBody,
    CardTitle, CardSubtitle, CardFooter
  } from 'reactstrap';
// core components
import SettingsHeader from "components/Headers/SettingsHeader.js";

import DashNavbar from "components/Navbars/DashNavbar.js";

import FooterWhite from "components/Footers/FooterWhite.js";

import contactapi from "../../api/contactapi";

import PropagateLoader from 'react-spinners/PropagateLoader'

import { connect } from 'react-redux';

import {useHistory} from 'react-router-dom';

import './../../index.css';

function DashContacts({user}) {
  const [contacts, setContacts] = useState(false);

  const history = useHistory();

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("settings-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });

  useEffect(() => {
    if (user?.role !== 'Engineer' && user?.role !== 'Admin') {
      console.log('yolo')
      history.push('/')
      return () => {};
    } else {
        getContactRequests();
    }
  }, [])

  const getContactRequests = async () =>
    await contactapi
      .get("/contact")
      .then((res) => {
          console.log(res.data)
          let data = res.data.filter((d) => d?.Service === "Cordial Shoppe");
          setContacts(data.sort((a,b) => new Date(b.CreateDate) - new Date(a.CreateDate)));
      })
      .catch((err) => {
        console.log("Getting Contact Data Error: ", err);
        return [];
      });

      function sendMail(to, subject="", from="", body="") {
        var link = `mailto:${to}`
                 + "?subject=" + encodeURIComponent("RE: Cordial Shoppe Contact Request")
                 + "&body=" + encodeURIComponent(body)
        ;
        
        window.location.href = link;
    }

    const generateContacts = (arr) => 
    arr.map((d, i) => 
        <Card key={i}>
            <CardBody>
                <CardTitle tag="h5">{d?.Name}</CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">{d?.Mobile} {d?.Email ? <span>&bull; {d?.Email}</span> : '' }</CardSubtitle>
                <CardText>{d?.Message}</CardText>
                <Button onClick={() => sendMail(d?.Email)}>Send Email</Button>
                <CardFooter className="mt-3" style={{display: "flex", justifyContent: "flex-end"}}>
                    <small className="text-muted">{dayjs(d?.CreateDate).format('ddd[,] MMMM D[,] YYYY [at] h:mm:ss A')}</small>
                </CardFooter>
            </CardBody>
        </Card>
    )


//   useEffect(async () => {
//     setContacts(await getContactRequests());
//   }, []);

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <SettingsHeader />
        <div className="profile-content section">
          <Container>
            <h2 className="title">
              <small>Contact Requests</small>
            </h2>
            <hr />
                {
                    contacts ? 
                        <div className="card-container">
                            {generateContacts(contacts)}
                        </div>
                    :
                        <div className="" style={{margin: '10px 19px 23px 0', display: 'flex', gap: "10px", marginRight: '19px', justifyContent: 'center'}}>
                        <PropagateLoader color={"lightblue"} loading={true} size={20}/>
                        </div>
                }
            <div className="text-center">
              <Button
                className="btn-wd btn-round"
                color="info"
                type="submit"
                href="/dash"
              >
                Back
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
    return { user: state.AccountReducer };
  }
  

export default connect(mapStateToProps, {})(DashContacts);
