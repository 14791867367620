import React from "react";
import Select from "react-select";

// reactstrap components
import { FormGroup } from "reactstrap";

function SubTopping(props) {
  let options = [
    {
      value: "0",
      label: "No " + props.data.name,
    },
    {
      value: "1",
      label: "Regular " + props.data.name,
    },
    {
      value: "2",
      label: "Extra " + props.data.name,
    },
  ];

  const [optionSelect, setOptionSelect] = React.useState(options[props.data.value]);

  if (props.data.value !== 0 && typeof props.data.value === "number") {
    props.data.setValue(options[props.data.value].label);
  }

  let prepValue = (option) => {
    setOptionSelect(option);
    props.data.setValue(option.label);
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("product-page");
    return function cleanup() {
      document.body.classList.remove("product-page");
    };
  });

  return (
    <>
      <FormGroup>
        <Select
          className="react-select react-select-default"
          classNamePrefix="react-select"
          name="top"
          value={optionSelect}
          onChange={(value) => prepValue(value)}
          options={options}
          placeholder={"Regular " + props.data.name}
        />
      </FormGroup>
    </>
  );
}

export default SubTopping;
