import React, { useState } from "react";
import "./TipModal.css";

import {
  Button,
  ButtonGroup,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// custom component
import LoadingSpinner from "../../components/LoadingSpinner";

export const MarginWrapper = ({ margin, children }) => (
  <span style={{ margin: `${margin}px` }}>{children}</span>
);

const TipModal = ({
  modalIsOpen,
  toggle,
  total,
  tipAction,
  submitAction,
  noThanksAction,
  loading,
  setLoading,
}) => {
  const originalTotal = +total;
  const pricesUderTheButtons = [
    (originalTotal * 0.1).toFixed(2),
    (originalTotal * 0.15).toFixed(2),
    (originalTotal * 0.2).toFixed(2),
  ];

  const [totalAfterTip, setTotalAfterTip] = useState(+total);
  const [customTip, setCustomTip] = useState(false);
  const [customTipAmount, setCustomTipAmount] = useState(0);

  const handleTotalChange = (tip) => {
    setCustomTip(false);
    setCustomTipAmount(0);
    setTotalAfterTip((originalTotal + originalTotal * (tip / 100)).toFixed(2));
    tipAction({ percent: tip, custom: 0 });
  };
  const addCustomTip = () => {
    if (customTipAmount < 0) {
      setTotalAfterTip((originalTotal + 0));
      tipAction({ percent: 0, custom: 0 });
    }
    setTotalAfterTip((originalTotal + Number(customTipAmount)).toFixed(2));
    tipAction({ percent: 0, custom: Number(customTipAmount) });
  };

  const showCustomTipInput = () => {
    if (customTip) {
      return (
        <div style={{ marginLeft: "60px", marginTop: "10px" }}>
          <div className="input-group" style={{ width: "280px" }}>
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              style={{ height: "41px" }}
              type="number"
              min="1"
              className="form-control"
              placeholder="10.00"
              onChange={(e) => {
                if (e.target.value < 0) e.target.value = 1;
                setCustomTipAmount(e.target.value)}
              }
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={addCustomTip}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div>
        <Modal isOpen={modalIsOpen} toggle={toggle}>
          <div className="modal-header modal-header-override">
            <h5>Leave a tip?</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={toggle}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            {!loading ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <ButtonGroup>
                    <button
                      className="btn btn-default"
                      style={{ color: "#fff" }}
                      onClick={() => handleTotalChange(10)}
                    >
                      10%
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => handleTotalChange(15)}
                    >
                      15%
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => handleTotalChange(20)}
                    >
                      20%
                    </button>
                    <button
                      className="btn btn-default"
                      style={{ color: "#fff" }}
                      onClick={() => {
                        setTotalAfterTip(+total);
                        setCustomTip(!customTip);
                      }}
                    >
                      Custom
                    </button>
                  </ButtonGroup>
                  <div className="pricesUnderTheButtons-container">
                    {pricesUderTheButtons.map((price) => (
                      <p className="pricesUnderTheButtons">${price}</p>
                    ))}
                  </div>
                </div>
                {showCustomTipInput()}
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <p style={{ fontWeight: "600" }}>
                    <b>Total after tip: ${totalAfterTip}</b>
                  </p>
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <LoadingSpinner
                  loading={loading}
                  color="#808080"
                  setLoading={setLoading}
                  size={30}
                />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <MarginWrapper margin={10} >
              <Button id="submit_order_button" color="success" onClick={submitAction}>
                Send tip!
              </Button>
            </MarginWrapper>
            <MarginWrapper margin={10}></MarginWrapper>
            <MarginWrapper margin={10} style={{ textAlign: "right" }}>
              <MarginWrapper margin={10}>
                <a href="javascript:void(0)" id="submit_order_button2" onClick={noThanksAction}>
                  No thanks
                </a>
              </MarginWrapper>
            </MarginWrapper>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default TipModal;
