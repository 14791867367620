import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// reactstrap components
import { Button, ButtonGroup, Table, Container, Row, Col } from "reactstrap";
import { toast, Zoom } from "react-toastify";

// core components
//import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import ContactUs from "./ContactUs.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import cordialShoppeApi from "../../api/cordialShoppe";

import StoreClosedPrompt from "./StoreClosedPrompt";

import "./CartPage.css";

import {
    removeItemFromCart,
    updateItemCartQty,
} from "../../Reducers/Actions/userActions";

let validOrderTime = true;

let { envs } = require("../../env");

//Wait time:
let waitTime = 20;

function CartPage(props) {
    document.documentElement.classList.remove("nav-open");

    const [CMSWAITTIME, setCMSWAITTIME] = useState(false);

    const settings = useSelector((state) => state.SettingsReducer);

    const getWaitTimeSetup = async () => {
        try {
            let response = await cordialShoppeApi.get("/settings");
            for (let setting of response.data) {
                if (setting.SettingID === "CMSWAITTIME") {
                    setCMSWAITTIME(setting.Value);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = (iid) => {
        for (let i in props.cart) {
            if (!props.cart[i].ID || props.cart[i].ID === iid) {
                console.log("purge item:", props.cart[i]);
                props.onCartRemoveItem(props.cart[i]);
            }
        }
        toast.success(`Item successfully deleted!`, {
            containerId: "B",
            transition: Zoom,
        });
    };
    const handleQtyDown = (iid) => {
        for (let i in props.cart) {
            if (!props.cart[i].ID || props.cart[i].ID === iid) {
                props.cart[i].Quantity--;
                if (props.cart[i].Quantity > 0) {
                    props.onCartUpdateQty(props.cart[i]);
                } else {
                    props.onCartRemoveItem(props.cart[i]);
                }
            }
        }
    };
    const handleQtyUp = (iid) => {
        for (let i in props.cart) {
            if (!props.cart[i].ID || props.cart[i].ID === iid) {
                props.cart[i].Quantity++;
                props.onCartUpdateQty(props.cart[i]);
            }
        }
    };

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        getWaitTimeSetup();
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    waitTime = 20;
    if (CMSWAITTIME && CMSWAITTIME > waitTime) {
        waitTime = CMSWAITTIME;
    }

    let total = 0;
    let subtotal = 0;
    let itemCount = 0;
    let realRows = [];
    for (let i in props.cart) {
        let item = props.cart[i];

        if (!item.Price) {
            item.Price = 10;
        }

        if (item.DeliWaitTime) {
            if (item.DeliWaitTime > waitTime) {
                waitTime = item.DeliWaitTime * item.Quantity;
            } else {
                waitTime += item.DeliWaitTime * item.Quantity;
            }
        }

        total += item.Price * item.Quantity;
        itemCount = parseInt(itemCount) + parseInt(item.Quantity);

        let itemTotal = item.Price.toFixed(2) * item.Quantity;
        itemTotal = itemTotal.toFixed(2);

        realRows.push(
            <tr>
                {/* <td>
          <div className="img-container">
            <img alt="..." src={item.Image} />
          </div>
        </td> */}
                <td className="td-product">
                    <strong>{item.Name}</strong>
                </td>
                <td className="td-product">
                    <p>{item.Description}</p>
                </td>
                <td className="td-price">
                    <small>$</small>
                    {item.Price.toFixed(2)}
                </td>
                <td className="td-number td-quantity">
                    <div className="qty-number">{item.Quantity} </div>
                    <ButtonGroup className="qty-btns">
                        <Button
                            className="btn-border btn-round qty-down"
                            color="default"
                            size="sm"
                            onClick={() => handleQtyDown(item.ID)}
                        >
                            -
                        </Button>
                        <Button
                            className="btn-border btn-round qty-delete"
                            color="default"
                            size="sm"
                            onClick={() => handleDelete(item.ID)}
                        >
                            <i
                                className="fa fa-trash"
                                style={{ color: "black" }}
                            />
                        </Button>
                        <Button
                            className="btn-border btn-round qty-up"
                            color="default"
                            size="sm"
                            onClick={() => handleQtyUp(item.ID)}
                        >
                            +
                        </Button>
                    </ButtonGroup>
                    {/* </div> */}
                </td>
                <td className="td-number">
                    <small>$</small>
                    {itemTotal}
                </td>
            </tr>
        );
    }

    //Check for update in CMS:
    if (CMSWAITTIME && CMSWAITTIME > waitTime) {
        waitTime = CMSWAITTIME;
    }

    let tax = total * 0.06;
    tax = tax.toFixed(2);

    subtotal = total;
    total = parseFloat(total) + parseFloat(tax);
    total = total.toFixed(2);

    return (
        <>
            <StoreClosedPrompt />
            <DangerNavbar />
            <div className="wrapper">
                <div className="blog-2 section section-gray">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="12">
                                {validOrderTime ? (
                                    <>
                                        <h4>
                                            <a href="/menu">Back to Menu</a>
                                        </h4>

                                        <br />
                                        <Row>
                                            <Col md="12">
                                                <h2
                                                    className="title"
                                                    id="cart-page-h2"
                                                >
                                                    Shopping Cart
                                                </h2>
                                            </Col>
                                            <Col
                                                className="ml-auto mr-auto cart-table-col"
                                                md="10"
                                            >
                                                <Table className="table-shopping cart-table">
                                                    <thead>
                                                        <tr className="table-headings">
                                                            <th className="text-center">
                                                                Item
                                                            </th>
                                                            <th className="text-center">
                                                                Notes
                                                            </th>
                                                            <th className="text-right price-heading">
                                                                Price
                                                            </th>
                                                            <th className="text-right">
                                                                Quantity
                                                            </th>
                                                            <th className="text-right">
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {realRows}

                                                        <tr className="subtotal-row">
                                                            <td colSpan="2" />
                                                            <td />
                                                            <td className="td-total">
                                                                {itemCount} Item
                                                                Sub Total
                                                            </td>
                                                            <td className="td-total">
                                                                <small>$</small>
                                                                {subtotal.toFixed(
                                                                    2
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr className="tax-row">
                                                            <td colSpan="2" />
                                                            <td />
                                                            <td className="td-total">
                                                                6% Tax
                                                            </td>
                                                            <td className="td-total">
                                                                <small>$</small>
                                                                {tax}
                                                            </td>
                                                        </tr>
                                                        <tr className="total-row">
                                                            <td colSpan="2" />
                                                            <td />
                                                            <td className="td-total">
                                                                Total
                                                            </td>
                                                            <td className="td-total">
                                                                <small>$</small>
                                                                {total}
                                                            </td>
                                                        </tr>
                                                        {itemCount &&
                                                        itemCount > 0 ? (
                                                            <tr className="tr-actions">
                                                                <td
                                                                    colSpan="3"
                                                                    className="text-left"
                                                                    style={{
                                                                        color: "red",
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Estimated
                                                                    Wait Time:{" "}
                                                                    {waitTime}{" "}
                                                                    minutes
                                                                </td>
                                                                <td
                                                                    className="text-right"
                                                                    colSpan="2"
                                                                >
                                                                    {settings.storeOpen ? (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            type="button"
                                                                            className="btn-fill btn-round checkout-btn"
                                                                            href="/order"
                                                                        >
                                                                            Go
                                                                            To
                                                                            Checkout
                                                                            <i className="fa fa-chevron-right" />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color=""
                                                                            disabled
                                                                            size="sm"
                                                                            type="button"
                                                                            className="btn-fill btn-round store-closed-btn"
                                                                            href="/order"
                                                                        >
                                                                            Online
                                                                            Store
                                                                            Closed
                                                                            <i className="fa fa-chevron-right" />
                                                                        </Button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            <br />
                                            Sorry, we are not taking online
                                            orders right now.
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row></Row>
                    </Container>
                </div>
            </div>

            <br />

            <FooterWhite />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onCartClear: (data) => {
        //   dispatch(clearCart());
        // },
        onCartRemoveItem: (data) => {
            dispatch(removeItemFromCart(data));
        },
        onCartUpdateQty: (data) => {
            dispatch(updateItemCartQty(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
//export default CartPage;
