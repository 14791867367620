import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
//import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import MenuCardPizza from "components/Menu/MenuCardPizza.js";
import MenuCardDeliPizza from "components/Menu/MenuCardDeliPizza.js";
import MenuCardSalad from "components/Menu/MenuCardSalad.js";
import MenuCardSpecial from "components/Menu/MenuCardSpecial.js";
import MenuCardSubs from "components/Menu/MenuCardSubs.js";
import MenuCardChicken from "components/Menu/MenuCardChicken.js";
import MenuCardCatering from "components/Menu/MenuCardCatering.js";
import ContactUs from "./ContactUs.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import cordialShoppeApi from "../../api/cordialShoppe";

import StoreClosedPrompt from "./StoreClosedPrompt";

let validOrderTime = true; //current_hour >= open_time && current_hour < close_time ? true : false;

function MenuPage(props) {
    const [onlineOrders, setOnlineOrders] = useState(false);

    const getSettingsSetup = async () => {
        try {
            let response = await cordialShoppeApi.get("/settings");
            for (let setting of response.data) {
                if (setting.SettingID === "OnlineOrders") {
                    setOnlineOrders(setting.Value);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        getSettingsSetup();
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <DangerNavbar />
            <div className="wrapper">
                <div className="blog-2 section section-gray">
                    <Container>
                        {/* store closed prompt */}
                        <StoreClosedPrompt />
                        <Row>
                            <Col className="ml-auto mr-auto" md="10">
                                <h2 className="title" id="menu-page-h2">
                                    Menu Sections
                                </h2>

                                <br />
                                {onlineOrders && validOrderTime ? (
                                    <>
                                        <Row>
                                            <MenuCardPizza />
                                            <MenuCardDeliPizza />
                                            <MenuCardSubs />
                                        </Row>
                                        <Row>
                                            <MenuCardSalad />
                                            <MenuCardSpecial />
                                            <MenuCardChicken />
                                        </Row>
                                        <Row>
                                            <MenuCardCatering />
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Row>
                                            <p>
                                                Sorry, we are not taking online
                                                orders at this time.
                                            </p>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <br />

            <FooterWhite />
        </>
    );
}

export default MenuPage;
