import React from "react";
import { connect } from "react-redux";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
    Button,
    Card,
    Carousel,
    CarouselIndicators,
    CarouselCaption,
    CarouselItem,
    FormGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";
import { addItemToCart } from "../../Reducers/Actions/userActions";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar";

import MathTopping from "components/Menu/MathTopping";

import FooterWhite from "components/Footers/FooterWhite";

const shortid = require("shortid");

// carousel items
const carouselItems = [
    {
        src: require("assets/img/pizza2.jpg"),
        altText: "",
        caption: "",
    },
];

const sizeOptions = [
    { value: "1", label: "Small - 8 Slices" },
    { value: "2", label: "Medium - 10 Slices" },
    { value: "3", label: "Large - 12 Slices" },
    { value: "4", label: "Extra Large - 14 Slices" },
];

const typeOptions = [
    { value: "1", label: "Custom Round" },
    { value: "2", label: "Custom Deep Dish" },
    {
        value: "6",
        label: "The Special - Pepperoni, Ham, Mushroom, Onion, Green Pepper, & Bacon.",
    },
    {
        value: "3",
        price: 23.99,
        label: "Chicago - Cheese, Pepperoni, Ham, Italian Sausage, Ground Beef & Bacon",
    },
    {
        value: "4",
        price: 19.99,
        label: "Fire Cracker Chicken - Chicken, Red Onion, Bacon and Green Pepper",
    },
    {
        value: "5",
        price: 17.99,
        label: "Meat Lovers - Cheese, Pepperoni, Ham, Italian Sausage, Ground Beef & Bacon",
    },
    {
        value: "7",
        price: 17.99,
        label: "Vegetarian - Cheese, Mushroom, Onion, Green Pepper, Tomatoes, Black Olives & Green Olives",
    },
    // {
    //   value: "8", //Removed per marcus
    //   price: 15.99,
    //   label: "Hawaiian - Cheese, Ham, Pineapple & Bacon",
    // },
];

function OrderPizza(props) {
    const [topping1, setTopping1] = React.useState(0);
    const [topping2, setTopping2] = React.useState(0);
    const [topping3, setTopping3] = React.useState(0);
    const [topping4, setTopping4] = React.useState(0);
    const [topping5, setTopping5] = React.useState(0);
    const [topping6, setTopping6] = React.useState(0);
    const [topping7, setTopping7] = React.useState(0);
    const [topping8, setTopping8] = React.useState(0);
    const [topping9, setTopping9] = React.useState(0);
    const [topping10, setTopping10] = React.useState(0);
    const [topping11, setTopping11] = React.useState(0);
    const [topping12, setTopping12] = React.useState(0);
    const [topping13, setTopping13] = React.useState(0);

    const getPizzaPrice = () => {
        //console.log("topping1:", topping1);

        let price = 100.99;
        let top_price;
        let total_tops = 0;

        switch (typeSelect.value) {
            case "1": // Custom Round
                if (topping1 !== 0 && topping1.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping1.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping2 !== 0 && topping2.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping2.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping3 !== 0 && topping3.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping3.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping4 !== 0 && topping4.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping4.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping5 !== 0 && topping5.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping5.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping6 !== 0 && topping6.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping6.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping7 !== 0 && topping7.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping7.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping8 !== 0 && topping8.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping8.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping9 !== 0 && topping9.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping9.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping10 !== 0 && topping10.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping10.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping11 !== 0 && topping11.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping11.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping12 !== 0 && topping12.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping12.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }
                if (topping13 !== 0 && topping13.substr(0, 2) !== "No") {
                    total_tops++;
                    if (topping13.substr(0, 5) === "Extra") {
                        total_tops++;
                    }
                }

                if (sizeSelect.value === "1") {
                    // Small
                    top_price = 2;

                    if (total_tops === 0) {
                        price = 7.99;
                    } else if (total_tops === 1) {
                        price = 9.99;
                    } else if (total_tops === 2) {
                        price = 11.99;
                    } else if (total_tops === 3) {
                        price = 13.99;
                    } else if (total_tops === 4) {
                        price = 15.99;
                    } else if (total_tops === 5) {
                        price = 17.99;
                    } else {
                        price = 17.99 + top_price * (total_tops - 5);
                    }
                }
                if (sizeSelect.value === "2") {
                    // Medium
                    top_price = 2.5;

                    if (total_tops === 0) {
                        price = 9.99;
                    } else if (total_tops === 1) {
                        price = 10.99;
                    } else if (total_tops === 2) {
                        price = 13.99;
                    } else if (total_tops === 3) {
                        price = 15.99;
                    } else if (total_tops === 4) {
                        price = 17.99;
                    } else if (total_tops === 5) {
                        price = 19.99;
                    } else {
                        price = 19.99 + top_price * (total_tops - 5);
                    }
                }
                if (sizeSelect.value === "3") {
                    // Large
                    top_price = 3;

                    if (total_tops === 0) {
                        price = 10.99;
                    } else if (total_tops === 1) {
                        price = 12.99;
                    } else if (total_tops === 2) {
                        price = 15.99;
                    } else if (total_tops === 3) {
                        price = 18.99;
                    } else if (total_tops === 4) {
                        price = 21.99;
                    } else if (total_tops === 5) {
                        price = 24.99;
                    } else {
                        price = 24.99 + top_price * (total_tops - 5);
                    }
                }
                if (sizeSelect.value === "4") {
                    //X-Large
                    top_price = 4;

                    if (total_tops === 0) {
                        price = 11.99;
                    } else if (total_tops === 1) {
                        price = 14.99;
                    } else if (total_tops === 2) {
                        price = 17.99;
                    } else if (total_tops === 3) {
                        price = 20.99;
                    } else if (total_tops === 4) {
                        price = 23.99;
                    } else if (total_tops === 5) {
                        price = 26.99;
                    } else {
                        price = 26.99 + top_price * (total_tops - 5);
                    }
                }

                break;
            case "2": // Custom Deep Dish
                let extras = 0; // This is only used in the calculation below
                if (sizeSelect.value === "2") {
                    price = 10.99;
                    top_price = 2;
                } else {
                    price = 10.99;
                    top_price = 3;
                }

                // First topping $2 for both, then $2 or $3 each
                if (topping1 !== 0 && topping1.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping1.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping2 !== 0 && topping2.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping2.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping3 !== 0 && topping3.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping3.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping4 !== 0 && topping4.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping4.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping5 !== 0 && topping5.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping5.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping6 !== 0 && topping6.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping6.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping7 !== 0 && topping7.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping7.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping8 !== 0 && topping8.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping8.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping9 !== 0 && topping9.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping9.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping10 !== 0 && topping10.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping10.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping11 !== 0 && topping11.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping11.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping12 !== 0 && topping12.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping12.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                if (topping13 !== 0 && topping13.substr(0, 2) !== "No") {
                    total_tops++;
                    price += top_price;

                    // if (total_tops === 1 && sizeSelect.value === "3") {
                    //   price -= 0.5;
                    // }
                    if (topping13.substr(0, 5) === "Extra") {
                        price += top_price;
                        extras++;
                    }
                }
                //Needed to calculate the deep Dish pizzas based on selection and toppings
                if (total_tops < 2) {
                    if (sizeSelect.value === "2") {
                        price = 12.99 + extras * 2.5;
                    } else {
                        price = 13.99 + extras * 3;
                    }
                } else if (total_tops === 2 && sizeSelect.value === "2") {
                    price = 14.99 + extras * 2.5; //Needed to add another check for the custom md
                } else if (total_tops > 2) {
                    if (sizeSelect.value != "2") {
                        price += 1;
                    } else {
                        // console.log(total_tops);
                        if (total_tops > 3) {
                            //Need to calculate the total items added after 16.99 at $2.50 each
                            total_tops -= 3;
                            let newPrice = 16.99 + total_tops * 2.5;
                            price = newPrice + extras * 2.5;
                        }
                    }
                }

                break;
            case "3": // Chicago
                if (sizeSelect.value === "2") {
                    return 23.99;
                } else if (sizeSelect.value === "3") {
                    return 25.99;
                } else {
                    return 21.99;
                }
                break;
            case "4": //Fire Cracker
                if (sizeSelect.value === "2") {
                    return 19.99;
                } else if (sizeSelect.value === "3") {
                    return 22.99;
                } else if (sizeSelect.value === "4") {
                    return 25.99;
                } else {
                    return 16.99;
                }
            case "5": //Meat lover
                if (sizeSelect.value === "2") {
                    return 19.99;
                } else if (sizeSelect.value === "3") {
                    return 22.99;
                } else if (sizeSelect.value === "4") {
                    return 25.99;
                } else {
                    return 16.99;
                }
            case "6": // Special?
                if (sizeSelect.value === "2") {
                    return 19.99;
                } else if (sizeSelect.value === "3") {
                    return 22.99;
                } else if (sizeSelect.value === "4") {
                    return 25.99;
                } else {
                    return 16.99;
                }
                break;
            case "7": // Vegetarian
                if (sizeSelect.value === "2") {
                    return 17.99;
                } else if (sizeSelect.value === "3") {
                    return 19.99;
                }
                if (sizeSelect.value === "4") {
                    return 21.99;
                } else {
                    return 15.99;
                }
                break;
                // case "8": // Hawiian
                //   if (sizeSelect.value === "2") {
                //     return 15.99;
                //   } else {
                //     return 18.99;
                //   }
                break;
        }

        return price;
    };

    const addNewItemToCart = (props) => {
        //Added an extra check to prevent people from swapping to an allowable size then switching to XLG
        if (sizeSelect.value === "4" && typeSelect.value === "3") {
            toast.error("Chicago Pizza cannot be extra large!", {
                containerId: "B",
                transition: Zoom,
            });
            return;
        }
        // let pizza_name,
        //   pizza_desc = "";

        // let item_price, top_price;
        // if (sizeSelect.value === "1") {
        //   item_price = 7.99;
        //   top_price = 1.5;
        // }
        // if (sizeSelect.value === "2") {
        //   item_price = 8.99;
        //   top_price = 2;
        // }
        // if (sizeSelect.value === "3") {
        //   item_price = 9.99;
        //   top_price = 2.5;
        // }
        // if (sizeSelect.value === "4") {
        //   item_price = 10.99;
        //   top_price = 3;
        // }
        // if (sizeSelect.value === "13") {
        //   item_price = 10.99;
        //   top_price = 2;
        // }
        // if (sizeSelect.value === "14") {
        //   item_price = 11.99;
        //   top_price = 2.5;
        // }

        // if (sizeSelect.value < 5 || sizeSelect.value > 12) {
        //   let full_str = sizeSelect.label;
        //   let str_parts = full_str.split("-");
        //   pizza_name = str_parts[0].trim() + " Pizza";
        //   //pizza_name = colorSelect.label;

        let pizza_name;
        let pizza_desc = "";
        if (topping1 !== 0 && topping1.substr(0, 2) !== "No") {
            pizza_desc = pizza_desc + topping1;
        }
        if (topping2 !== 0 && topping2.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping2;
        }
        if (topping3 !== 0 && topping3.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping3;
        }
        if (topping4 !== 0 && topping4.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping4;
        }
        if (topping5 !== 0 && topping5.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping5;
        }
        if (topping6 !== 0 && topping6.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping6;
        }
        if (topping7 !== 0 && topping7.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping7;
        }
        if (topping8 !== 0 && topping8.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping8;
        }
        if (topping9 !== 0 && topping9.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping9;
        }
        if (topping10 !== 0 && topping10.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping10;
        }
        if (topping11 !== 0 && topping11.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping11;
        }
        if (topping12 !== 0 && topping12.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping12;
        }
        if (topping13 !== 0 && topping13.substr(0, 2) !== "No") {
            if (pizza_desc !== "") {
                pizza_desc = pizza_desc + ", ";
            }
            pizza_desc = pizza_desc + topping13;
        }

        //   if (pizza_desc === "") {
        //     pizza_desc = "Cheese only";
        //   }
        // } else {
        //  let full_str = typeSelect.label;
        //  let str_parts = full_str.split("-");
        //  pizza_name = str_parts[0].trim() + " Pizza";
        //  pizza_desc = str_parts[1].trim();
        //  item_price = sizeSelect.price;
        // }

        let full_str = typeSelect.label;
        let str_parts = full_str.split("-");
        let size_parts = sizeSelect.label.split("-");
        pizza_name = size_parts[0] + " " + str_parts[0].trim() + " Pizza";

        if (pizza_desc === "" && str_parts.length > 1) {
            pizza_desc = str_parts[1].trim();
        }

        if (pizza_desc === "") {
            pizza_desc = "Cheese only";
        }

        let pizza_notes = "";
        let notes_field = document.getElementById("pizzaNotes");
        if (notes_field.value !== "") {
            pizza_notes = "\nCustomer Notes: " + notes_field.value;
        } else {
            //pizza_notes = " | No customer notes.";
        }

        let cart_item = {
            ID: shortid.generate(),
            Name: pizza_name,
            Description: pizza_desc + pizza_notes,
            Price: getPizzaPrice(), // item_price,
            Quantity: quantitySelect.value,
        };
        console.log("cart_item:", cart_item);

        props.onItemAdd(cart_item);
        toast.success("Item added to cart!", {
            containerId: "B",
            transition: Zoom,
        });
    };

    const [sizeSelect, setSizeSelect] = React.useState({
        value: "3",
        label: "Large - 12 Slices",
    });
    const [typeSelect, setTypeSelect] = React.useState({
        value: "1",
        label: "Custom Round",
    });
    const [quantitySelect, setQuantitySelect] = React.useState({
        value: "1",
        label: "1",
    });

    const updateTypeDisplay = (option) => {
        let addToppingsDiv = document.getElementById("addToppingsDiv");
        if (option.value < 3) {
            addToppingsDiv.style.display = "block";
        } else {
            addToppingsDiv.style.display = "none";
        }

        // Size Protections
        if (
            option.value === "2" &&
            (parseInt(sizeSelect.value) < 2 || parseInt(sizeSelect.value) > 3)
        ) {
            toast.error("Deep Dish cannot be small or extra large!", {
                containerId: "B",
                transition: Zoom,
            });
        } else if (option.value === "3" && parseInt(sizeSelect.value) > 3) {
            toast.error("Chicago Pizza cannot be extra large!", {
                containerId: "B",
                transition: Zoom,
            });
        } else if (
            (option.value === "4" ||
                option.value === "5" ||
                option.value === "7") &&
            parseInt(sizeSelect.value) > 3
        ) {
            toast.error("This pizza cannot be extra large!", {
                containerId: "B",
                transition: Zoom,
            });
        } else {
            setTypeSelect(option);
        }
    };

    const updateSizeDisplay = (option) => {
        // Deep Dish Size Protection
        if (
            typeSelect.value === "2" &&
            (parseInt(option.value) < 2 || parseInt(option.value) > 3)
        ) {
            toast.error("Deep Dish cannot be small or extra large!", {
                containerId: "B",
                transition: Zoom,
            });
        } else {
            setSizeSelect(option);
        }
    };

    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    });
    return (
        <>
            <DangerNavbar />
            {/* <ProductPageHeader /> */}
            <br />
            <div className="main">
                <div className="section">
                    <Container>
                        <Row className="title-row">
                            <Col md="12">
                                <h4 className="shop">
                                    <a href="/menu">Back to Menu</a>
                                </h4>
                            </Col>
                            {/* <Col className="ml-auto" md="4">
                <div className="pull-right">
                  <span className="text-muted">Order Status</span>
                  <Button color="link">
                    <i className="fa fa-shopping-cart" /> 0 Items
                  </Button>
                </div>
              </Col> */}
                        </Row>
                        <Row>
                            <Col md="7" sm="6">
                                <div className="ml-auto mr-auto" id="carousel">
                                    <Card className="page-carousel">
                                        <Carousel
                                            activeIndex={activeIndex}
                                            next={next}
                                            previous={previous}
                                        >
                                            <CarouselIndicators
                                                items={carouselItems}
                                                activeIndex={activeIndex}
                                                onClickHandler={goToIndex}
                                            />
                                            {carouselItems.map((item) => {
                                                return (
                                                    <CarouselItem
                                                        onExiting={onExiting}
                                                        onExited={onExited}
                                                        key={item.src}
                                                    >
                                                        <img
                                                            src={item.src}
                                                            alt={item.altText}
                                                        />
                                                        <CarouselCaption
                                                            captionText={
                                                                item.caption
                                                            }
                                                            captionHeader=""
                                                        />
                                                    </CarouselItem>
                                                );
                                            })}
                                            <a
                                                className="left carousel-control carousel-control-prev"
                                                data-slide="prev"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    previous();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-left" />
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="right carousel-control carousel-control-next"
                                                data-slide="next"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    next();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-right" />
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </Carousel>
                                    </Card>
                                </div>
                                {/* end carousel */}

                                <div>
                                    <br />
                                    <h2>Prices</h2>
                                    <table class="table">
                                        <thead>
                                            <th></th>
                                            <th></th>
                                            <th>Round Pizza</th>
                                            <th></th>
                                            <th></th>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-center">
                                                    Small
                                                </th>
                                                <th class="text-center">
                                                    Medium
                                                </th>
                                                <th class="text-center">
                                                    Large
                                                </th>
                                                <th class="text-center">XL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Cheese</td>
                                                <td class="text-center">
                                                    $7.99
                                                </td>
                                                <td class="text-center">
                                                    $9.99
                                                </td>
                                                <td class="text-center">
                                                    $10.99
                                                </td>
                                                <td class="text-center">
                                                    $11.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cheese 1 item</td>
                                                <td class="text-center">
                                                    $9.99
                                                </td>
                                                <td class="text-center">
                                                    $10.99
                                                </td>
                                                <td class="text-center">
                                                    $12.99
                                                </td>
                                                <td class="text-center">
                                                    $14.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cheese 2 items</td>
                                                <td class="text-center">
                                                    $11.99
                                                </td>
                                                <td class="text-center">
                                                    $13.99
                                                </td>
                                                <td class="text-center">
                                                    $15.99
                                                </td>
                                                <td class="text-center">
                                                    $17.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cheese 3 items</td>
                                                <td class="text-center">
                                                    $13.99
                                                </td>
                                                <td class="text-center">
                                                    $15.99
                                                </td>
                                                <td class="text-center">
                                                    $18.99
                                                </td>
                                                <td class="text-center">
                                                    $20.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cheese 4 items</td>
                                                <td class="text-center">
                                                    $15.99
                                                </td>
                                                <td class="text-center">
                                                    $17.99
                                                </td>
                                                <td class="text-center">
                                                    $21.99
                                                </td>
                                                <td class="text-center">
                                                    $23.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cheese 5 items</td>
                                                <td class="text-center">
                                                    $17.99
                                                </td>
                                                <td class="text-center">
                                                    $19.99
                                                </td>
                                                <td class="text-center">
                                                    $24.99
                                                </td>
                                                <td class="text-center">
                                                    $26.99
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Extra Items ea.</td>
                                                <td class="text-center">
                                                    $2.00
                                                </td>
                                                <td class="text-center">
                                                    $2.50
                                                </td>
                                                <td class="text-center">
                                                    $3.00
                                                </td>
                                                <td class="text-center">
                                                    $4.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Extra Cheese</td>
                                                <td class="text-center">
                                                    $2.00
                                                </td>
                                                <td class="text-center">
                                                    $2.50
                                                </td>
                                                <td class="text-center">
                                                    $3.00
                                                </td>
                                                <td class="text-center">
                                                    $4.00
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <th></th>
                                            <th></th>
                                            <th>Deep Dish Pizza</th>
                                            <th></th>
                                            <th></th>
                                        </thead>
                                        <tr>
                                            <td>Cheese 1 item</td>
                                            <td class="text-center">-</td>
                                            <td class="text-center">$12.99</td>
                                            <td class="text-center">$13.99</td>
                                            <td class="text-center">-</td>
                                        </tr>
                                        <tr>
                                            <td>Cheese 2 items</td>
                                            <td class="text-center">-</td>
                                            <td class="text-center">$14.99</td>
                                            <td class="text-center">$16.99</td>
                                            <td class="text-center">-</td>
                                        </tr>
                                        <tr>
                                            <td>Cheese 3 items</td>
                                            <td class="text-center">-</td>
                                            <td class="text-center">$16.99</td>
                                            <td class="text-center">$20.99</td>
                                            <td class="text-center">-</td>
                                        </tr>
                                        <tr>
                                            <td>Extra Items ea.</td>
                                            <td class="text-center">-</td>
                                            <td class="text-center">$2.50</td>
                                            <td class="text-center">$3.00</td>
                                            <td class="text-center">-</td>
                                        </tr>
                                        {/* <thead>
                      <th></th>
                      <th></th>
                      <th>Chicago Pizza</th>
                      <th></th>
                      <th></th>
                    </thead>
                    <tr>
                      <td></td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">$19.99</td>
                      <td class="text-center">$22.99</td>
                    </tr>
                    <thead>
                      <th></th>
                      <th></th>
                      <th>Specialty Pizza</th>
                      <th></th>
                      <th></th>
                    </thead>
                    <tr>
                      <td>Fire Cracker</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">$18.99</td>
                    </tr>
                    <tr>
                      <td>Meat Lover</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">$18.99</td>
                    </tr>
                    <tr>
                      <td>Vegetarian</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">$16.99</td>
                    </tr>
                    <tr>
                      <td>Hawaiian</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center">$15.99</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr> */}
                                    </table>
                                </div>
                            </Col>
                            <Col md="5" sm="6">
                                <h2>Pizza</h2>
                                <hr />
                                <p>
                                    Our classic home-made hand-tossed pizzas are
                                    made fresh every day.
                                </p>
                                <br />
                                <Row>
                                    <Col md="12" sm="12">
                                        {/* <label>Select starting pizza</label> */}
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="sizeSelect"
                                                value={sizeSelect}
                                                onChange={(value) =>
                                                    updateSizeDisplay(value)
                                                }
                                                options={sizeOptions}
                                                placeholder="COLOR"
                                            />
                                            <br />
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="typeSelect"
                                                value={typeSelect}
                                                onChange={(value) =>
                                                    updateTypeDisplay(value)
                                                }
                                                options={typeOptions}
                                                placeholder="COLOR"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row
                                    id="addToppingsDiv"
                                    style={{ display: "block" }}
                                >
                                    <Col>
                                        <label>Select Toppings</label>

                                        <FormGroup>
                                            <MathTopping
                                                data={{
                                                    label: "Pepperoni",
                                                    name: "Pepperoni",
                                                    value: topping1,
                                                    setValue: setTopping1,
                                                }}
                                            />

                                            <MathTopping
                                                data={{
                                                    label: "Ham",
                                                    name: "Ham",
                                                    value: topping2,
                                                    setValue: setTopping2,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Bacon",
                                                    name: "Bacon",
                                                    value: topping3,
                                                    setValue: setTopping3,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Italian Sausage",
                                                    name: "Italian Sausage",
                                                    value: topping4,
                                                    setValue: setTopping4,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Ground Beef",
                                                    name: "Ground Beef",
                                                    value: topping5,
                                                    setValue: setTopping5,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Mushroom",
                                                    name: "Mushroom",
                                                    value: topping6,
                                                    setValue: setTopping6,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Onion",
                                                    name: "Onion",
                                                    value: topping7,
                                                    setValue: setTopping7,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Green Pepper",
                                                    name: "Green Pepper",
                                                    value: topping8,
                                                    setValue: setTopping8,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Green Olive",
                                                    name: "Green Olive",
                                                    value: topping9,
                                                    setValue: setTopping9,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Black Olive",
                                                    name: "Black Olive",
                                                    value: topping10,
                                                    setValue: setTopping10,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Banana Peppers",
                                                    name: "Banana Peppers",
                                                    value: topping11,
                                                    setValue: setTopping11,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Anchovies",
                                                    name: "Anchovies",
                                                    value: topping12,
                                                    setValue: setTopping12,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Pineapple",
                                                    name: "Pineapple",
                                                    value: topping13,
                                                    setValue: setTopping13,
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row
                                    id="addToCartDiv"
                                    style={{ display: "block" }}
                                >
                                    <hr />

                                    <Col md="12" sm="12">
                                        <label>Quantity</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="quantitySelect"
                                                value={quantitySelect}
                                                onChange={(value) =>
                                                    setQuantitySelect(value)
                                                }
                                                options={[
                                                    { value: "1", label: "1" },
                                                    { value: "2", label: "2" },
                                                    { value: "3", label: "3" },
                                                    { value: "4", label: "4" },
                                                    { value: "5", label: "5" },
                                                    { value: "6", label: "6" },
                                                    {
                                                        value: "7",
                                                        label: "7",
                                                    },
                                                    {
                                                        value: "8",
                                                        label: "8",
                                                    },
                                                    {
                                                        value: "9",
                                                        label: "9",
                                                    },
                                                    {
                                                        value: "10",
                                                        label: "10",
                                                    },
                                                ]}
                                                placeholder="QUANTITY"
                                            />

                                            <br />

                                            <textarea
                                                className="form-control"
                                                id="pizzaNotes"
                                                rows="5"
                                                placeholder="Notes...."
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col className="offset-md-5" md="7" sm="8">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="danger"
                                            onClick={() =>
                                                addNewItemToCart(props)
                                            }
                                        >
                                            Add to Cart  
                                            <i className="fa fa-chevron-right" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <FooterWhite />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAdd: (data) => {
            dispatch(addItemToCart(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPizza);
