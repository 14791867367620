import React from "react";
import { Link, useHistory } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  Button,
  NavbarBrand,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { signout } from "../../Reducers/Actions/userActions";



// core components

function MultiDropdownNavbar({ user, signout }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const history = useHistory();
  const signOut = () => {
    signout();
    history.push("/signin");
  };

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("bg-info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar class="nav-sticky"
        className={classnames("fixed-top", navbarColor)}
        id="navbar-main"
        expand="lg"
      >
        <Container class="container-fluid">
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/index" tag={Link} style={{fontSize: 'clamp(0.78rem, 6vw, 2.5rem)'}}>
            {/* <img
                src={require("../../assets/img/newlogo.png")}

                class="img-responsive"
                width="50px"
                className="align-center"
                alt="Stacks & Cordials logo"
              /> */}
              {' '}The Cordial Shoppe
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              est. 1986
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {user?.role === "Engineer" || user?.role === "Admin" ? (
                <NavItem>
                  <NavLink style={{ padding: 0, margin: 0 }} href="/dash">
                    <Button color="success">
                      <i className="nc-icon nc-settings-gear-65 mr-1" />{" "}
                      Dashboard
                    </Button>
                  </NavLink>
                </NavItem>
              ) : (
                <></>
              )}
              {/* <NavItem>
                <Button class="" href="#contact-section" color="success">
                  <i className="nc-icon nc-email-85 mr-1" /> Contact
                </Button>
              </NavItem> */}
              <NavItem>
                {user.token ? (
                  <Button class="" color="danger" onClick={signOut}>
                    <i className="nc-icon nc-simple-remove mr-1" /> Logout
                  </Button>
                ) : (
                  <NavLink style={{ padding: 0, margin: 0 }} href="/signin">
                    <Button class="" color="info">
                      <i className="nc-icon nc-single-02 mr-1" /> Login
                    </Button>
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
};

export default connect(mapStateToProps, { signout })(MultiDropdownNavbar);
