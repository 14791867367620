import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Container,
  Table,
} from "reactstrap";

import {useHistory} from 'react-router-dom';

import { connect } from 'react-redux';

// core components
import SettingsHeader from "components/Headers/SettingsHeader.js";

import DashNavbar from "components/Navbars/DashNavbar.js";
import DashOrderItem from "./DashOrderItem.js"
import FooterWhite from "components/Footers/FooterWhite.js";
import cordialShoppeApi from "../../api/cordialShoppe";
import OrderSpecial from "./MenuPageSpecial";

function DashOrders({user}) {
  const [orders, setOrders] = useState([]);
  const history = useHistory();

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("settings-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });
  const listOrders = async (token) => {
    try {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,   
        }
      };

      let response = await cordialShoppeApi.get("/orders", headers);
      setOrders(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user?.role !== 'Engineer' && user?.role !== 'Admin') {
      history.push('/')
    } else {
      listOrders(user.token)
    }
  }, [])

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <SettingsHeader />
        <div className="profile-content section">
          <Container>
            <h2 className="title">
              <small>Order Report</small>
            </h2>
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{fontWeight: '700'}} className="text-center" >#</th>
                  <th style={{fontWeight: '700'}} >Order Id</th>
                  <th style={{fontWeight: '700'}} >Name</th>
                  <th style={{fontWeight: '700'}} >Phone</th>
                  <th style={{fontWeight: '700'}} >Item Count</th>
                  <th style={{fontWeight: '700'}} >Date</th>
                  <th style={{fontWeight: '700'}} >Printed</th>
                  <th style={{fontWeight: '700'}} className="text-right" >Order Total</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.length > 0
                  ? orders.map((order, index) => (
                     <DashOrderItem order = {order} index = {index} />
                    ))
                  : " "}
              </tbody>
            </Table>
            <div className="text-center">
              <Button
                className="btn-wd btn-round"
                color="info"
                type="submit"
                href="/dash"
              >
                Back
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
}

export default connect(mapStateToProps, {})(DashOrders);
