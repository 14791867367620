import { ReactReduxContext } from "react-redux";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_QTY,
} from "./Actions/userActions";
const initialStore = {
  id: "",
  cart: [],
};

export default function reducer(store = initialStore, action) {
  let { type, payload } = action;

  switch (type) {
    case ADD_TO_CART:
      let newCart = [...store.cart];
      let lastIndex = store.cart.length;

      newCart[lastIndex] = payload.item;
      return Object.assign({}, store, {
        cart: newCart,
      });
    case REMOVE_FROM_CART:
      let newCart2 = [...store.cart];

      for (let i in newCart2) {
        if (newCart2[i].ID === payload.item.ID) {
          newCart2.splice(i, 1);
        }
      }
      return Object.assign({}, store, {
        cart: newCart2,
      });

    case UPDATE_CART_QTY:
      let newCart3 = [...store.cart];

      for (let i in newCart3) {
        if (newCart3[i].ID === payload.item.ID) {
          newCart3[i].Quantity = payload.item.Quantity;
        }
      }
      return Object.assign({}, store, {
        cart: newCart3,
      });
    default:
      return store;
  }
}
