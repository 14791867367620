import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Container,
  Table,
} from "reactstrap";

import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';


// core components
import SettingsHeader from "components/Headers/SettingsHeader.js";
import DashNavbar from "components/Navbars/DashNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import cordialShoppeApi from '../../api/cordialShoppe';


function DashUsers({ user }) {
  const [accounts, setAccounts] = useState([]);
  const history = useHistory();

  // let token = 0;

  //const [accounts, setAccounts] = useState({});
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("settings-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });

  useEffect(() => {
    if (user?.role !== 'Engineer' && user?.role !== 'Admin') {
      console.log('yolo')
      history.push('/')
    } else {
      listUsers(user.token);
    }
  }, [])

  const listUsers = async (token) => {
    try {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }
      };

      let response = await cordialShoppeApi.get('/accounts', headers);

      setAccounts(response.data);

    } catch (err) {
      //dispatch({ type: 'ADD_MESSAGE', payload: err.response.data.Message });
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("string", accounts);
  }, [accounts]);

  let c = 1;

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <SettingsHeader />
        <div className="profile-content section">
          <Container>
            <h2 className="title">
              <small>User Report</small>
            </h2>
            <Table hover>
              <thead>
                <tr>
                  <th style={{ fontWeight: '700' }}>#</th>
                  <th style={{ fontWeight: '700' }}>Name</th>
                  <th style={{ fontWeight: '700' }}>Role</th>
                  <th style={{ fontWeight: '700' }}>Email</th>
                  <th style={{ fontWeight: '700' }}>Cell</th>
                  <th style={{ fontWeight: '700' }}>Sign Up</th>
                </tr>
              </thead>
              <tbody>
                {
                  accounts ?
                    accounts.map((d, i) =>
                      <tr key={i}>
                        <td style={{ fontWeight: '400' }}>{c++}</td>
                        <td style={{ fontWeight: '400' }}>{d.FirstName} {d.LastName}</td>
                        <td style={{ fontWeight: '400' }}>{d.Role ? d.Role : "User"}</td>
                        <td style={{ fontWeight: '400' }}>{d.Email}</td>
                        <td style={{ fontWeight: '400' }}>{d.Cell}</td>
                        <td style={{ fontWeight: '400' }}>{d.CreateDate.split('T')[0]}</td>
                      </tr>
                    )
                    :
                    <p>Retrieving Information</p>
                }
              </tbody>
            </Table>
            <div className="text-center">
              <Button
                className="btn-wd btn-round"
                color="info"
                type="submit"
                href="/dash"
              >
                Back
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
}


export default connect(mapStateToProps, {})(DashUsers);