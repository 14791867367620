import React from "react";

import StoreClosedPrompt from "./../../views/cordail/StoreClosedPrompt";

import "./LandingPageHeaderCordial.css";

// reactstrap components
import { Button, Row, Col, Container } from "reactstrap";
import { useSelector } from "react-redux";

// core components

function LandingPageHeader(props) {
    const settings = useSelector((state) => state.SettingsReducer);
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    return (
        <>
            <div
                className="page-header"
                ref={pageHeader}
                style={{
                    backgroundImage:
                        "url(" + require("assets/img/bottle.png") + ")",
                }}
            >
                <div className="filter" />
                <div className="content-center">
                    <StoreClosedPrompt />
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="iframe-container">
                                    <iframe
                                        title="google-map"
                                        width="450"
                                        height="250"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDinD2ow5O1dIEf9buLPo0lXR7d2qXxg-4&q=9045+Telegraph+Rd,+Taylor,+MI+48180"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </Col>
                            <Col className="ml-auto" md="6">
                                <h2 className="title">Welcome!</h2>
                                <h5 className="description">
                                    <strong>
                                        Read more about the store below, or
                                        place an order with Stacks & Cordials!
                                    </strong>
                                </h5>
                                <br />
                                {props.data.OnlineOrders &&
                                settings.storeOpen ? (
                                    <Button color="danger" href="/menu">
                                        <i className="nc-icon nc-bag-16 mr-1" />
                                        Order Now
                                    </Button>
                                ) : (
                                    <Button color="danger" href="/menu">
                                        <i className="nc-icon nc-bag-16 mr-1" />
                                        See Menu
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default LandingPageHeader;
