module.exports = {
  saladTypes: [
    {
      value: "1",
      label: "Antipasto",
    },
    {
      value: "2",
      label: "Chef",
    },
    {
      value: "3",
      label: "Greek",
    },
    {
      value: "4",
      label: "Garden",
    },
  ],
  dressingTypes: [
    {
      value: "0",
      label: "None",
    },
    {
      value: "1",
      label: "Italian",
    },
    {
      value: "2",
      label: "Extra Italian",
    },
    {
      value: "3",
      label: "Ranch",
    },
    {
      value: "4",
      label: "Extra Ranch",
    },
    {
      value: "5",
      label: "Greek",
    },
    {
      value: "6",
      label: "Extra Greek",
    },
  ],
  styleTypes: [
    {
      value: "1",
      label: "Oven Baked",
    },
    {
      value: "2",
      label: "Soft Roll",
    },
    {
      value: "3",
      label: "Hard Roll",
    },
  ],
  typeTypes: [
    {
      value: "1",
      label: "Suicide Killer",
    },
    {
      value: "2",
      label: "Italian",
    },
    {
      value: "3",
      label: "Steak N' Cheese",
    },
    {
      value: "4",
      label: "Po' Boy",
    },
    {
      value: "5",
      label: "Vegetarian",
    },
    {
      value: "6",
      label: "Taylor T.P.",
    },
    {
      value: "7",
      label: "Turkey",
    },
    {
      value: "8",
      label: "Club",
    },
    {
      value: "9",
      label: "Ham N' Cheese",
    },
    {
      value: "10",
      label: "Roast Beef",
    },
    {
      value: "11",
      label: "Corned Beef",
    },
    {
      value: "12",
      label: "Pizza Sub",
    },
    {
      value: "13",
      label: "BLT",
    },
    // <option value="7">Turkey</option>
    // <option value="8">Club</option>
    // <option value="9">Ham N' Cheese</option>

    {
      value: "14",
      label: "Firecracker",
    },
    {
      value: "15",
      label: "Mr. Telegraph",
    },
  ],

  chicken: [
    // {
    //   title: "Chicken by the piece",
    //   note: "",
    //   items: [
    //     {
    //       value: "1",
    //       label: "Leg - $1.25",
    //       price: 1.25
    //     },
    //     {
    //       value: "2",
    //       label: "Breast - $2.99",
    //       price: 2.99
    //     },
    //     {
    //       value: "3",
    //       label: "Thigh - $1.49",
    //       price: 1.49
    //     },
    //     {
    //       value: "4",
    //       label: "Wing - $1.25",
    //       price: 1.25
    //     },
    //   ],
    // },
    {
      title: "Just Chicken",
      note: "",
      items: [
        {
          value: "0",
          label: "4 Pc - $7.99",
          price: 7.99
        },
        // {
        //   value: "10",
        //   label: "3 Legs - $3.75",
        //   price: 3.75
        // },
        // {
        //   value: "11",
        //   label: "3 Wings - $3.75",
        //   price: 3.75
        // },
        // {
        //   value: "12",
        //   label: "3 Thighs - $4.50",
        //   price: 4.50
        // },
        {
          value: "2",
          label: "8 Pc - $13.99",
          price: 13.99
        },
        // {
        //   value: "3",
        //   label: "8 Pc Dark - $9.99",
        //   price: 9.99
        // },
        // {
        //   value: "4",
        //   label: "8 Pc White - $15.99",
        //   price: 15.99
        // },
        {
          value: "5",
          label: "12 Pc - $19.99",
          price: 19.99
        },
        {
          value: "6",
          label: "16 Pc - $25.99",
          price: 25.99
        },
        {
          value: "7",
          label: "20 Pc - $31.99",
          price: 31.99
        },
        {
          value: "8",
          label: "50 Pc - $75.00",
          price: 75.00
        },
        {
          value: "9",
          label: "75 Pc - $110.00",
          price: 110.00
        }, {
          value: "10",
          label: "100 Pc - $145.00",
          price: 145.00
        }
      ],
    },
    {
      title: "Chicken Combos",
      note: "includes potatoes, coleslaw and a roll.",
      items: [
        {
          value: "1",
          label: "4 Pc - 10.99",
          price: 10.99
        },
        {
          value: "2",
          label: "8 Pc - $19.99",
          price: 19.99
        },
        // {
        //   value: "3",
        //   label: "8 Pc Dark - $12.99",
        //   price: 12.99
        // },
        // {
        //   value: "3",
        //   label: "8 Pc White - $19.99",
        //   price: 19.99
        // },
        {
          value: "4",
          label: "12 Pc - $29.99",
          price: 29.99
        },
        {
          value: "5",
          label: "16 Pc - $35.99",
          price: 35.99
        },
        {
          value: "6",
          label: "20 Pc - $42.99",
          price: 42.99
        },
      ],
    },
    {
      title: "Fiesta Bites",
      note: "$10.99 per LB",
      items: [
        {
          value: "1",
          label: "1 LB",
          price: 10.99
        },
        {
          value: "2",
          label: "2 LB",
          price: 21.98
        },

        {
          value: "3",
          label: "3 LB",
          price: 32.97
        },

        {
          value: "5",
          label: "5 LB",
          price: 54.95
        },

        {
          value: "10",
          label: "10 LB",
          price: 109.90
        },
      ]
    },
    {
      title: "Chicken Tenders and Fries",
      note: "includes fries, coleslaw and a roll.",
      items: [
        {
          value: "1",
          label: "4 Pc - 9.99",
          price: 9.99
        },
        {
          value: "2",
          label: "8 Pc - $17.99",
          price: 17.99
        },
        {
          value: "4",
          label: "12 Pc - $26.99",
          price: 26.99
        },
        {
          value: "5",
          label: "16 Pc - $33.99",
          price: 33.99
        },
        {
          value: "5",
          label: "20 Pc - $39.99",
          price: 39.99
        },
      ],
    },
    {
      title: "Wing Dings",
      note: "6 for $9.99",
      items: [
        {
          value: "1",
          label: "Mild",
          price: 9.99
        },
        {
          value: "2",
          label: "BBQ",
          price: 9.99
        },
        {
          value: "3",
          label: "Buffalo",
          price: 9.99
        },
      ],
    },
  ],



  specialty: [
    {
      title: "Bread Sticks",
      note: "",
      items: [
        {
          value: "1",
          label: "$6.99",
          price: 6.99
        },
      ],
    },
    {
      title: "Cordial Roll (Pepperoni Roll) ",
      note: "$6.99",
      items: [
        {
          value: "1",
          label: "$6.99",
          price: 6.99
        },
      ],
    },
    {
      title: "Cheese Bread",
      note: "",
      items: [
        {
          value: "1",
          label: "$7.99",
          price: 7.99
        },
      ],
    },
    {
      title: "Chicken Shawarma",
      note: "$5.99 each, comes with garlic sauce and pickles.",
      price: 5.99,
      notes: 1
    },
    {
      title: "Jalapeno Cheese Bread",
      note: "",
      items: [
        {
          value: "1",
          label: "$8.99",
          price: 8.99
        },
      ],
    },
    {
      title: "Potato Wedges",
      note: "",
      items: [
        {
          value: "1",
          label: "Small - $3.99",
          price: 3.99
        },
        {
          value: "2",
          label: "Large - $6.99",
          price: 6.99
        },
      ],
    },
    // {
    //   title: "Peperoni Sticks",
    //   note: "",
    //   items: [
    //     {
    //       value: "1",
    //       label: "$7.99",
    //       price: 7.99
    //     },
    //   ],
    // },
    {
      title: "French Fries",
      note: "",
      items: [
        {
          value: "1",
          label: "Small - $2.99",
          price: 2.99
        },
        {
          value: "2",
          label: "Large - $5.99",
          price: 5.99
        },
      ],
    },
    {
      title: "6 Bosco Sticks",
      note: "",
      items: [
        {
          value: "1",
          label: "$7.99",
          price: 7.99
        },
      ],
    },
    {
      title: "Onion Rings",
      note: "",
      items: [
        {
          value: "1",
          label: "Small - $2.99",
          price: 2.99
        },
        {
          value: "2",
          label: "Large - $5.99",
          price: 5.99
        },
      ],
    },
  ],



  catering: [
    {
      title: "Sub Trays",
      note: "Mixed",
      wait: 45,
      items: [
        {
          value: "1",
          label: '16" mixed - $65',
          price: 65
        },
        {
          value: "2",
          label: '18" mixed - $75',
          price: 75
        },
      ],
    },
    {
      title: '16" Deli Tray',
      note: "$65.00",
      wait: 65,
      items: [
        {
          value: "1",
          label: "Ham, Turkey, Salami, Roast Beef, Swiss, and American",
          price: 65
        },
      ],
    },
    {
      title: "Salad Tray",
      note: "$24.99 for a half tray, $39.99 for a full tray.",
      notes: 1,
      wait: 45,
      items: [
        {
          value: "1",
          label: "Half Chef Salad Tray",
          price: 24.99
        },
        {
          value: "2",
          label: "Full Chef Salad Tray",
          price: 39.99
        },
        {
          value: "3",
          label: "Half Greek Salad Tray",
          price: 24.99
        },
        {
          value: "4",
          label: "Full Greek Salad Tray",
          price: 39.99
        },
        {
          value: "5",
          label: "Half Antipasto Salad Tray",
          price: 24.99
        },
        {
          value: "6",
          label: "Full Antipasto Salad Tray",
          price: 39.99
        },
        {
          value: "7",
          label: "Half Garden Salad Tray",
          price: 24.99
        },
        {
          value: "8",
          label: "Full Garden Salad Tray",
          price: 39.99
        },
      ],
    },
    {
      title: "Big Sheet Party Pepperoni Pizza",
      note: "$39.99 + Extra Toppings $9.00 each and will be charged separate",
      notes: 1,
      wait: 45,
      items: [
        {
          value: "1",
          label: "$39.99",
          price: 39.99
        },
      ],
    },
    // {
    //   title: "Italian Subs By The Foot",
    //   wait: 45,
    //   items: [
    //     {
    //       value: "1",
    //       label: "3 Feet - $35.99",
    //       price: 35.99
    //     },
    //     {
    //       value: "1",
    //       label: "6 Feet - $71.99",
    //       price: 71.99
    //     },
    //   ],
    // },
    {
      title: "French Fries",
      note: "Half Sheet Tray - $24.99",
      wait: 45,
      items: [
        {
          value: "1",
          label: "Half Sheet Tray - $24.99",
          price: 24.99
        },
      ],
    },
    {
      title: "Onion Rings",
      note: "Half Sheet Tray - $24.99",
      wait: 45,
      items: [
        {
          value: "1",
          label: "Half Sheet Tray - $24.99",
          price: 24.99
        },
      ],
    },
    {
      title: "Potato Wedges",
      note: "Half Sheet Tray - $29.99",
      wait: 45,
      items: [
        {
          value: "1",
          label: "Half Sheet Tray - $29.99",
          price: 29.99
        },
      ],
    },
  ],
};
