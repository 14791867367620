import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {connect} from 'react-redux'
import {
  Row,
  Col,
  Button,
  NavbarBrand,
  Navbar,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components

function WhiteNavbar(props) {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [setCollapseOpen] = React.useState(false);

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });

  let itemCount = 0;
  for (let i in props.cart) {
    let item = props.cart[i];
    itemCount = parseInt(itemCount) + parseInt(item.Quantity);
  }

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main" color="danger">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
              The Cordial Shoppe
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              est. 1986
            </UncontrolledTooltip>
            {/* <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button> */}
          </div>
          <Row>
            <Col className="ml-auto" md="12">
              <div className="pull-right">
                {/* <span className="text-muted">Order Status</span> */}
                <Button color="link" href="/cart">
                  <i className="fa fa-shopping-cart" /> {itemCount} Items
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => {

  return {

    cart : state.UserReducer.cart

  }
}

export default connect(mapStateToProps)(WhiteNavbar)


