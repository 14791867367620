import React from "react";
// plugin that creates slider
// import Slider from "nouislider";

// reactstrap components
import { Card, CardBody, CardTitle, Col } from "reactstrap";

function MenuSalad() {
  return (
    <>
      <Col md="4">
        <a href="/menu-salad" id="menu-page-salad-menu-link" >
          <Card className="card-blog">
            <div className="card-image">
              <img
                alt="..."
                className="img img-raised"
                src={require("assets/img/fixsalad.jpg")}
              />
            </div>
            <CardBody>
              <CardTitle tag="h5">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Salad
                </a>
              </CardTitle>
              <p className="card-description">
                Click here to start building a salad!
                <br />
              </p>
            </CardBody>
          </Card>
        </a>
      </Col>
    </>
  );
}

export default MenuSalad;
