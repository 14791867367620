import UserReducer from './UserReducer';
import AccountReducer from './accountReducer';
import SettingsReducer from './SettingsReducer';

export default {

  UserReducer,
  AccountReducer,
  SettingsReducer

};