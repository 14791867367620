//import Item from "components/Cart/Item";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// reactstrap components
import { Table } from "reactstrap";

const DashOrderItem = ({ order, index }) => {
    const [showItems, setShowItems] = useState(false);

    const onClickItems = () => setShowItems(!showItems);

    const generateItemTable = (items) => {
        return items.map((item, index) => (
            <tr key={index}>
                <td style={{ fontWeight: "400" }}>{item.Name}</td>
                <td style={{ fontWeight: "400" }}>{item.Quantity}</td>
                <td style={{ fontWeight: "400" }}>{item.Description}</td>
                <td style={{ fontWeight: "400" }}>$ {item.Price}</td>
                <td style={{ fontWeight: "400" }}>
                    $ {item.Price * item.Quantity}
                </td>
            </tr>
        ));
    };

    return (
        <>
            <tr key={index}>
                <td style={{ fontWeight: "400" }} className="text-center">
                    {index + 1}
                </td>
                <td style={{ fontWeight: "400" }}>{order.TransactionId}</td>
                <td style={{ fontWeight: "400" }}>{order.Name}</td>
                <td style={{ fontWeight: "400" }}>{order.Phone}</td>
                <td style={{ fontWeight: "400" }}>
                    {order.Items.length}
                    <span
                        onClick={onClickItems}
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            marginLeft: "10px",
                            backgroundColor: "#CCC",
                            padding: "5px",
                        }}
                    >
                        <FontAwesomeIcon icon={faCaretRight} />
                    </span>
                </td>
                {/* <td style={{fontWeight: '400'}}>{order.CreateDate.split("T")[0]}</td> */}
                <td style={{ fontWeight: "400" }}>
                    {dayjs(order.CreateDate).format("MMMM D[,] [at] h:mm:ss A")}
                </td>
                <td style={{ fontWeight: "400" }}>
                    {order.Printed ? "Yes" : "No"}
                </td>
                <td style={{ fontWeight: "400" }} className="text-right">
                    $ {parseFloat(order.Total).toFixed(2)}
                </td>
            </tr>
            {showItems ? (
                <>
                    <td colspan="100%">
                        <Table>
                            <thead>
                                <th colSpan="5">
                                    Pickup Time: {order.OrderDate}
                                </th>
                            </thead>
                            <thead>
                                <th>Name</th>
                                <th>Qty</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Total</th>
                            </thead>
                            <tbody>{generateItemTable(order.Items)}</tbody>
                        </Table>
                    </td>
                </>
            ) : (
                ""
            )}
            {/* </Card>
      </Collapse> */}
        </>
    );
};

export default DashOrderItem;
