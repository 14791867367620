/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
const packageJson = require('../../../package.json');

function FooterWhite() {
  return (
    <>
      <footer className="footer footer-white" id="cs-footer-white" >
        <Container>
          <Row>
            <nav className="footer-nav" >
              <ul>
                <li>
                  <a href="/index" className="mr-1">
                  © {new Date().getFullYear()} The Cordial Shoppe v{packageJson.version}
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
                Made with <i className="fa fa-heart heart" /> by{" "}
                <a target="_blank" href="https://www.pingsoftwareusa.com">
                  Ping Software USA
                </a>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterWhite;
