const dayjs = require("dayjs");

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

// const hours = [
//     {
//         // Sunday
//         Open: '10:00',
//         Close: '20:00'
//     },
//     {
//         // Monday
//         Open: '10:00',
//         Close: '21:00'
//     },
//     {
//         // Tuesday
//         Open: '10:00',
//         Close: '21:00'
//     },
//     {
//         // Wednesday
//         Open: '10:00',
//         Close: '21:00'
//     },
//     {
//         // Thursday
//         Open: '10:00',
//         Close: '21:00'
//     },
//     {
//         // Friday
//         Open: '10:00',
//         Close: '22:00'
//     },
//     {
//         // Saturday
//         Open: '10:00',
//         Close: '22:00'
//     },
// ]

const Hours = {
  opening: [
    "10:00",
    "10:00",
    "10:00",
    "10:00",
    "10:00",
    "10:00",
    "10:00",
    "10:00",
  ],
  closing: ["20:00", "21:00", "21:00", "21:00", "21:00", "22:00", "22:00"],
};

/**
 * Takes a time string and creates useable array. Can also handle with array of times.
 * @param {String | Array.<String>} time : time string or object
 * @param {Number} [day=false] (if object) which day to get [0 (Sunday) - 7 (Saturday)]
 * @return {Array.<Number>} time array where 0 index is hour and 1 index is minute (ex. [13, 30] => 1:30 PM)
 **/
const getTime = (time, day = false) => {
  let res = [];
  
  if (day === false) time.split(":").forEach((i) => res.push(Number(i)));
  else time[day].split(":").forEach((i) => res.push(Number(i)));

  return res;
};

/**
 *   Calculates whether the store is currently open or not.
 *   @param {{opening: Array.<String>, closing: Array.<String>}} hours open and close hours
 *   @param {String} now time now (in string) (if not set, current time comes from dayjs)
 *   @param {Number} [TIME_OFF=30] Offset for time to check for (default 30 for half hour)
 *   @param {Boolean} halfHours whether to account for half hours
 *   @param {String} TIMEZONE Timezone for dayjs
 *   @return bool value indicating if the store is open (true - open, false - closed)
 **/
const calcTime = (
  value,
  now = false,
  TIME_OFF = 30,
  halfHours = false,
  TIMEZONE = "America/Detroit"
) => {
  // half hour const
  const TIME_OFFSET = TIME_OFF;
  const { $m: min, $W: day, $H: hour } = dayjs().tz(TIMEZONE);

  let current;
  const open = getTime(value.opening, day, hour);
  const close = getTime(value.closing, day);

  if (now === false) {
    current = [hour, min];
  } else current = getTime(now);

  // 9 == 9
  if (current[0] === open[0]) {
    if (!halfHours) {
      // if we dont care for half hours, open as soon as hour is reached
      console.log(`[D] ${2}`);
      return true;
    }

    // we care for half hours
    // 31 > 30 or 30 == 30
    if (current[1] >= open[1] + TIME_OFFSET) {
      return true; // open
    }

    return false;
  } else if (current[0] === close[0]) {
    // 8pm === 8pm
    if (current[1] < close[1]) {
      return true;
    }

    return false;
  } else if (current[0] > open[0] && current[0] < close[0]) {
    // 20 (8pm) > 10 (10am) > 9 (9am) => true

    // if 30 minutes before close
    if (current[0] === close[0] - 1 && current[1] >= close[1] + TIME_OFFSET) {
      return false;
    }

    // before half hour till closing
    return true;
  }

  // Everything else
  return false;
};

module.exports = {
  Hours,
  calcTime,
};
