import cordialShoppeApi from "../../api/cordialShoppe";
import { toast, Zoom } from "react-toastify";

export const GET_HOURS = "GET_HOURS";
export const SET_HOURS = "SET_HOURS";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_STOREOPEN = "SET_STOREOPEN";
export const SET_HOURSREADY = "SET_HOURSREADY";

export const getHours = () => async (dispatch) =>
    await cordialShoppeApi
        .get("/settings?SettingID=CMSHours")
        .then((res) => {
            console.log("GETTING HOURS", res.data);
            dispatch({
                type: GET_HOURS,
                payload: { Hours: res.data.Hours, HoursReady: true },
            });
        })
        .catch((err) => {
            dispatch({ type: "ADD_MESSAGE", payload: err });
        });

export const setHours = (new_hours, token) => async (dispatch) => {
    const headers = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };

    // save new times to DB
    await cordialShoppeApi
        .post(
            "/setting",
            {
                SettingID: "CMSHours",
                Hours: new_hours,
            },
            headers
        )
        .then((res) => {
            if (res.status >= 300) throw new Error("Bad Response from API");
            // send new hours to reducer
            dispatch({
                type: "SET_HOURS",
                payload: new_hours,
            });
        })
        .catch((err) => {
            dispatch({ type: "ADD_MESSAGE", payload: err });
        });
};

export const requestSent = (text, code) => async (dispatch) => {
    if (code === true) {
        toast.success(text, {
            containerId: "B",
            transition: Zoom,
        });
    } else {
        toast.error(text, {
            containerId: "B",
            transition: Zoom,
        });
    }
    dispatch({ type: "NULL", payload: "" });
};

export const setChosenDay = (day) => async (dispatch) =>
    dispatch({ type: "SET_CHOSEN_DAY", payload: day });
export const setTime = (time) => async (dispatch) =>
    dispatch({ type: "SET_TIME", payload: time });
export const setTime2 = (time2) => async (dispatch) =>
    dispatch({ type: "SET_TIME2", payload: time2 });
export const setHoursReady = (h) => async (dispatch) =>
    dispatch({ type: "SET_HOURSREADY", payload: h });

export const setStoreOpen = (isOpen) => async (dispatch) => {
    console.log("are we open:", isOpen);
    dispatch({ type: SET_STOREOPEN, payload: isOpen });
};
