import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './Reducers';



function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

const store = createStore( combineReducers({
  ...reducers,
}), loadFromLocalStorage(), applyMiddleware(thunk));

//DOC listen for store changes and use saveToLocalStorage to save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()))  

// save them to localStorage
export default store