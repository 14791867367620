import React, { useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  Button,
  NavbarBrand,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import { connect } from 'react-redux';
import { signout } from '../../Reducers/Actions/userActions';


// core components

function DashNavbar({ user, signout }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const history = useHistory();
  const signOut = () => {
    signout();
    history.push('/signin');
  }

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("bg-info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        id="navbar-main"
        expand="lg"
      >
        <div className="font-adjust" id="font-adjust">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" className="navbar-brand" to="/index" tag={Link} style={{fontSize: 'clamp(0.78rem, 6vw, 2.5rem)'}}>
              The Cordial Shoppe
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              est. 1986
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
            <NavItem>
                  <NavLink style={{padding:0, margin:0}} href="/dash">
                    <Button class="" color="success">
                      <i className="nc-icon nc-settings-gear-65 mr-1" /> Dashboard
                    </Button>
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink style={{padding:0, margin:0}} href="/dash/orders">
                    <Button class="" color="success">
                      <i className="nc-icon nc-money-coins mr-1" /> Orders
                    </Button>
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink style={{padding:0, margin:0}} href="/dash/users">
                    <Button class="" color="success">
                      <i className="nc-icon nc-bullet-list-67 mr-1" /> Users
                    </Button>
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink style={{padding:0, margin:0}} href="/dash/contacts">
                    <Button class="" color="success">
                      <i className="nc-icon nc-mobile" /> Contacts
                    </Button>
                  </NavLink>
              </NavItem>
              <NavItem>
                {user.token ?
                  <Button
                    class=""
                    color="danger"
                    onClick={signOut}>
                    <i className="nc-icon nc-simple-remove mr-1" /> Logout
                  </Button>
                  :
                  <NavLink style={{padding:0, margin:0}} href="/signin">
                    <Button class="" color="info">
                      <i className="nc-icon nc-single-02 mr-1" /> Login
                    </Button>
                  </NavLink>
                }
              </NavItem>             
            </Nav>
          </Collapse>
        </Container>
        </div>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
}

export default connect(mapStateToProps, { signout })(DashNavbar);