import React from "react";
import Select from "react-select";
import { connect } from "react-redux";

// reactstrap components
import {
    Button,
    Card,
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";
import { addItemToCart } from "../../Reducers/Actions/userActions";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import PingProducts from "../../components/Cart/PingProducts";

const shortid = require("shortid");

// carousel items
const carouselItems = [
    {
        src: require("assets/img/fixsalad.jpg"),
        altText: "",
        caption: "",
    },
    // {
    //   src: require("assets/img/salad2.jpg"),
    //   altText: "",
    //   caption: "",
    // },
];

function OrderSalad(props) {
    const addNewItemToCart = (props) => {
        let item_price = 9.99;

        let salad_name = colorSelect.label + " Salad";

        let salad_notes = "";
        let notes_field = document.getElementById("saladNotes");
        if (notes_field.value !== "") {
            salad_notes = "\nCustomer Notes: " + notes_field.value;
        } else {
            //salad_notes = " | No customer notes.";
        }

        let dressing_note = "";
        if (dressingSelect.label !== "None") {
            dressing_note = " with " + dressingSelect.label + " dressing. ";

            if (dressingSelect.label.substr(0, 5) === "Extra") {
                item_price += 2;
            }
        }

        let cart_item = {
            ID: shortid.generate(),
            Name: salad_name,
            Description: salad_name + dressing_note + salad_notes,
            Price: item_price,
            Quantity: 1,
        };
        console.log(cart_item);

        props.onItemAdd(cart_item);
        toast.success("Item added to cart!", {
            containerId: "B",
            transition: Zoom,
        });

        // document.getElementById("add_to_cart_" + item.cid).style.display = "block";
        // document.getElementById("sending_to_cart_" + item.cid).style.display = "none";
    };

    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    const [colorSelect, setColorSelect] = React.useState({
        value: "1",
        label: "Antipasto",
    });
    const [dressingSelect, setDressingSelect] = React.useState({
        value: "0",
        label: "None",
    });

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    });
    return (
        <>
            <DangerNavbar />
            {/* <ProductPageHeader /> */}
            <br />
            <div className="main">
                <div className="section">
                    <Container>
                        <Row className="title-row">
                            <Col md="12">
                                <h4 className="shop">
                                    <a href="/menu">Back to Menu</a>
                                </h4>
                            </Col>
                            {/* <Col className="ml-auto" md="4">
                <div className="pull-right">
                  <span className="text-muted">Order Status</span>
                  <Button color="link">
                    <i className="fa fa-shopping-cart" /> 0 Items
                  </Button>
                </div>
              </Col> */}
                        </Row>
                        <Row>
                            <Col md="7" sm="6">
                                <div className="ml-auto mr-auto" id="carousel">
                                    <Card className="page-carousel">
                                        <Carousel
                                            activeIndex={activeIndex}
                                            next={next}
                                            previous={previous}
                                        >
                                            <CarouselIndicators
                                                items={carouselItems}
                                                activeIndex={activeIndex}
                                                onClickHandler={goToIndex}
                                            />
                                            {carouselItems.map((item) => {
                                                return (
                                                    <CarouselItem
                                                        onExiting={onExiting}
                                                        onExited={onExited}
                                                        key={item.src}
                                                    >
                                                        <img
                                                            src={item.src}
                                                            alt={item.altText}
                                                        />
                                                        <CarouselCaption
                                                            captionText={
                                                                item.caption
                                                            }
                                                            captionHeader=""
                                                        />
                                                    </CarouselItem>
                                                );
                                            })}
                                            <a
                                                className="left carousel-control carousel-control-prev"
                                                data-slide="prev"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    previous();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-left" />
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="right carousel-control carousel-control-next"
                                                data-slide="next"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    next();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-right" />
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </Carousel>
                                    </Card>
                                </div>
                                {/* end carousel */}

                                <h6 style={{ color: "red" }}>
                                    Adding items may change the price. $2.00 per
                                    extra item.
                                </h6>
                                <br />

                                <table
                                    class="table"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Sub</th>
                                            <th class="text-center">
                                                Description
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Chef Salad</td>
                                            <td>
                                                Lettuce, Tomatoes, Onions, Green
                                                Peppers, Black Olives, Green
                                                Olives, Pepperoncini, Hame,
                                                Turkey and Cheese.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Greek Salad</td>
                                            <td>
                                                Lettuce, Green Peppers, Beets,
                                                Black Olives, Red Onions,
                                                Tomatoes, Pepperoncini and Feta
                                                Cheese.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Garden Salad</td>
                                            <td>
                                                Lettuce, Tomatoes, Cucumbers,
                                                Onions, Green Peppers,
                                                Pepperoncini, Black Olives, and
                                                Green Olives.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Deluxe Antipasto</td>
                                            <td>
                                                Lettuce, Tomatoes, Salami,
                                                Pepperoni, Ham, Onions, Green
                                                Peppers, Black Olives, Green
                                                Olives, Pepperoncini and
                                                Mozzarella.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md="5" sm="6">
                                <h2>Salad Builder</h2>
                                <h4 className="price">
                                    <strong></strong>
                                </h4>
                                <hr />
                                <p>Type:</p>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="colorSelect"
                                    value={colorSelect}
                                    onChange={(value) => setColorSelect(value)}
                                    options={PingProducts.getSaladTypes()}
                                    placeholder="Antipasto"
                                />
                                <br />

                                <p>Dressing:</p>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="dressingSelect"
                                    value={dressingSelect}
                                    onChange={(value) =>
                                        setDressingSelect(value)
                                    }
                                    options={PingProducts.getDressingTypes()}
                                    placeholder="None"
                                />
                                <br />
                                <br />

                                <textarea
                                    className="form-control"
                                    id="saladNotes"
                                    rows="5"
                                    placeholder="Notes...."
                                />

                                <br />
                                <Button
                                    block
                                    className="btn-round"
                                    color="danger"
                                    id={"add_to_cart"}
                                    onClick={() => addNewItemToCart(props)}
                                >
                                    Add to Cart  
                                    <i className="fa fa-chevron-right" />
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <FooterWhite />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAdd: (data) => {
            dispatch(addItemToCart(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSalad);
