import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

// reactstrap components
import {
    Button,
    Card,
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";
import { addItemToCart } from "../../Reducers/Actions/userActions";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import PingProducts from "../../components/Cart/PingProducts";
import SubTopping from "../../components/Menu/SubTopping";

const shortid = require("shortid");

// carousel items
const carouselItems = [
    {
        src: require("assets/newSK3.png"),
        altText: "",
        caption: "",
    },
];

function OrderSubs(props) {
    const [topping1, setTopping1] = React.useState(1);
    const [topping2, setTopping2] = React.useState(1);
    const [topping3, setTopping3] = React.useState(1);
    const [topping4, setTopping4] = React.useState(1);
    const [topping5, setTopping5] = React.useState(1);
    const [specTopping1, setSpecTopping1] = React.useState(0);
    const [specTopping2, setSpecTopping2] = React.useState(0);
    const [specTopping3, setSpecTopping3] = React.useState(0);

    const addToppingToItem = (topId, props) => {
        if (topId === 1) {
            setSpecTopping1(1);
            document.getElementById("add_parmesan").disabled = true;
            toast.success("Parmesan added!", {
                containerId: "B",
                transition: Zoom,
            });
        } else if (topId === 2) {
            setSpecTopping2(1);
            document.getElementById("add_bp").disabled = true;
            toast.success("Banana Peppers added!", {
                containerId: "B",
                transition: Zoom,
            });
        } else if (topId === 3) {
            setSpecTopping3(1);
            document.getElementById("add_jalapenos").disabled = true;
            toast.success("Jalapenos added!", {
                containerId: "B",
                transition: Zoom,
            });
        }
    };

    const addNewItemToCart = (props) => {
        let item_price = 9.99;

        let sub_style = styleSelect.label;
        let sub_type = typeSelect.label;
        let sub_name = sub_style + " " + sub_type;
        let sub_notes = "";

        // Tops and Price
        if (topping1 !== 0) {
            if (topping1.substr(0, 7) !== "Regular") {
                sub_notes = topping1 + ", ";
            }
            item_price += 0;
            if (topping1.substr(0, 5) === "Extra") {
                item_price += 0;
            }
        }
        if (topping2 !== 0) {
            if (topping2.substr(0, 7) !== "Regular") {
                sub_notes = sub_notes + topping2 + ", ";
            }
            item_price += 0;
            if (topping2.substr(0, 5) === "Extra") {
                item_price += 0;
            }
        }
        if (topping3 !== 0) {
            if (topping3.substr(0, 7) !== "Regular") {
                sub_notes = sub_notes + topping3 + ", ";
            }
            item_price += 0;
            if (topping3.substr(0, 5) === "Extra") {
                item_price += 0;
            }
        }
        if (topping4 !== 0) {
            if (topping4.substr(0, 7) !== "Regular") {
                sub_notes = sub_notes + topping4 + ", ";
            }
            item_price += 0;
            if (topping4.substr(0, 5) === "Extra") {
                item_price += 2;
            }
        }
        if (topping5 !== 0) {
            if (topping5.substr(0, 7) !== "Regular") {
                sub_notes = sub_notes + topping5 + ", ";
            }
            item_price += 0;
            if (topping5.substr(0, 5) === "Extra") {
                item_price += 1;
            }
        }

        // Add Spec Toppings
        if (specTopping1 === 1) {
            item_price += 0.5;
            sub_notes = sub_notes + "Parmesan" + ", ";
        }
        if (specTopping2 === 1) {
            item_price += 0.5;
            sub_notes = sub_notes + "Banana Peppers" + ", ";
        }
        if (specTopping3 === 1) {
            item_price += 0.5;
            sub_notes = sub_notes + "Jalapenos" + ", ";
        }

        if (dressingSelect.label !== "None") {
            sub_notes =
                sub_notes + ", and " + dressingSelect.label + " dressing. ";
        } else {
            //sub_notes = sub_notes + ".";
        }

        // Text Area Notes
        let notes_field = document.getElementById("subNotes");
        if (notes_field.value !== "") {
            sub_notes = sub_notes + "\nCustomer Notes: " + notes_field.value;
        } else {
            //sub_notes = sub_notes + " | No customer notes.";
        }

        let cart_item = {
            ID: shortid.generate(),
            Name: sub_name,
            Description: sub_notes,
            Price: item_price,
            Quantity: 1,
        };
        console.log("cart_item:", cart_item);

        props.onItemAdd(cart_item);
        toast.success("Item added to cart!", {
            containerId: "B",
            transition: Zoom,
        });

        // document.getElementById("add_to_cart_" + item.cid).style.display = "block";
        // document.getElementById("sending_to_cart_" + item.cid).style.display = "none";
    };

    const [styleSelect, setStyleSelect] = React.useState({
        value: "1",
        label: "Oven Baked",
    });

    const [typeSelect, setTypeSelect] = React.useState({
        value: "1",
        label: "Suicide Killer",
    });

    const [dressingSelect, setDressingSelect] = React.useState({
        value: "0",
        label: "None",
    });

    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    });
    return (
        <>
            <DangerNavbar />
            {/* <ProductPageHeader /> */}
            <br />
            <div className="main">
                <div className="section">
                    <Container>
                        <Row className="title-row">
                            <Col md="12">
                                <h4 className="shop">
                                    <a href="/menu">Back to Menu</a>
                                </h4>
                            </Col>
                            {/* <Col className="ml-auto" md="4">
                <div className="pull-right">
                  <span className="text-muted">Order Status</span>
                  <Button color="link">
                    <i className="fa fa-shopping-cart" /> 0 Items
                  </Button>
                </div>
              </Col> */}
                        </Row>
                        <Row>
                            <Col md="7" sm="6">
                                <div className="ml-auto mr-auto" id="carousel">
                                    <Card className="page-carousel">
                                        <Carousel
                                            activeIndex={activeIndex}
                                            next={next}
                                            previous={previous}
                                        >
                                            <CarouselIndicators
                                                items={carouselItems}
                                                activeIndex={activeIndex}
                                                onClickHandler={goToIndex}
                                            />
                                            {carouselItems.map((item) => {
                                                return (
                                                    <CarouselItem
                                                        onExiting={onExiting}
                                                        onExited={onExited}
                                                        key={item.src}
                                                    >
                                                        <img
                                                            src={item.src}
                                                            alt={item.altText}
                                                        />
                                                        <CarouselCaption
                                                            captionText={
                                                                item.caption
                                                            }
                                                            captionHeader=""
                                                        />
                                                    </CarouselItem>
                                                );
                                            })}
                                            <a
                                                className="left carousel-control carousel-control-prev"
                                                data-slide="prev"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    previous();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-left" />
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="right carousel-control carousel-control-next"
                                                data-slide="next"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    next();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-right" />
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </Carousel>
                                    </Card>
                                </div>
                                {/* end carousel */}

                                <h6 style={{ color: "red" }}>
                                    All subs come with lettuce, onion, tomoato,
                                    and house dressing.
                                </h6>
                                <br />

                                <table
                                    class="table"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Sub</th>
                                            <th class="text-center">
                                                Description
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Suicide Killer</td>
                                            <td>
                                                Cajun Turkey and Pepper Jack
                                                Cheese
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Italian</td>
                                            <td>
                                                Mortadella, Capicola,
                                                Prosciutto, Hard Salami and
                                                Cooked Salami
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Steak N' Cheese</td>
                                            <td>
                                                Slow Cooked Shaved Ribeye W/
                                                Mushrooms and Onions
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Po' Boy</td>
                                            <td>
                                                Fried White Fish, House Sauce
                                                and Fresh Pickles
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Vegetarian</td>
                                            <td>
                                                Mushroom, Onions, Green Peppers,
                                                Black Olives and Green Olives
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Firecracker</td>
                                            <td>
                                                Mushroom, Onions, Green Peppers,
                                                Black Olives and Green Olives
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Taylor T.P.</td>
                                            <td>
                                                Oven Roasted Turkey and Beef
                                                Pastrami
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Turkey</td>
                                            <td>Oven Roasted Turkey</td>
                                        </tr>
                                        <tr>
                                            <td>Club</td>
                                            <td>
                                                Oven Roasted Turkey and Ham with
                                                a touch of Bacon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Ham N' Cheese</td>
                                            <td>Thinly Sliced Ham</td>
                                        </tr>
                                        <tr>
                                            <td>Roast Beef</td>
                                            <td>Thinly Sliced Roast Beef</td>
                                        </tr>
                                        <tr>
                                            <td>Corned Beef</td>
                                            <td>Thinly Sliced Corned Beef</td>
                                        </tr>
                                        <tr>
                                            <td>Pizza Sub</td>
                                            <td>
                                                House Made Pizza Sauce, 100%
                                                Mozzarella, Pepperoni and Salami
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>BLT</td>
                                            <td>Bacon, Lettuce, and Tomato</td>
                                        </tr>
                                        <tr>
                                            <td>Mr. Telegraph</td>
                                            <td>
                                                Oven roasted turkey, and
                                                Pepperoni
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md="5" sm="6">
                                <h2>Subs</h2>
                                <h4 className="price">
                                    <strong></strong>
                                </h4>
                                <hr />
                                <p>Style:</p>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="styleSelect"
                                    value={styleSelect}
                                    onChange={(value) => setStyleSelect(value)}
                                    options={PingProducts.getStyleTypes()}
                                    placeholder="None"
                                />
                                <br />

                                <p>Type:</p>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="typeSelect"
                                    value={typeSelect}
                                    onChange={(value) => setTypeSelect(value)}
                                    options={PingProducts.getTypeTypes()}
                                    placeholder="None"
                                />
                                <br />

                                {/* <p>Dressing:</p>
                <Select
                  className="react-select react-select-default"
                  classNamePrefix="react-select"
                  name="dressingSelect"
                  value={dressingSelect}
                  onChange={(value) => setDressingSelect(value)}
                  options={PingProducts.getDressingTypes()}
                  placeholder="None"
                />
                <br /> */}

                                <br />
                                <br />
                                <p>Toppings:</p>
                                <SubTopping
                                    data={{
                                        extra: 0,
                                        name: "Lettuce",
                                        value: topping1,
                                        setValue: setTopping1,
                                    }}
                                />
                                <SubTopping
                                    data={{
                                        extra: 0,
                                        name: "Tomato",
                                        value: topping2,
                                        setValue: setTopping2,
                                    }}
                                />
                                <SubTopping
                                    data={{
                                        extra: 0,
                                        name: "Onions",
                                        value: topping3,
                                        setValue: setTopping3,
                                    }}
                                />
                                <SubTopping
                                    data={{
                                        extra: 2,
                                        name: "Meat",
                                        value: topping4,
                                        setValue: setTopping4,
                                    }}
                                />
                                <SubTopping
                                    data={{
                                        extra: 1,
                                        name: "Cheese",
                                        value: topping5,
                                        setValue: setTopping5,
                                    }}
                                />

                                {/* All 50 cents */}
                                <Button
                                    block
                                    className="btn-round"
                                    color="success"
                                    id={"add_parmesan"}
                                    onClick={() => addToppingToItem(1, props)}
                                >
                                    Add Parmesan
                                </Button>
                                <Button
                                    block
                                    className="btn-round"
                                    color="success"
                                    id={"add_bp"}
                                    onClick={() => addToppingToItem(2, props)}
                                >
                                    Add Banana Peppers
                                </Button>
                                <Button
                                    block
                                    className="btn-round"
                                    color="success"
                                    id={"add_jalapenos"}
                                    onClick={() => addToppingToItem(3, props)}
                                >
                                    Add Jalapenos
                                </Button>

                                <br />

                                <textarea
                                    className="form-control"
                                    id="subNotes"
                                    rows="5"
                                    placeholder="Notes...."
                                />

                                <br />
                                <br />
                                <Button
                                    block
                                    className="btn-round"
                                    color="danger"
                                    id={"add_to_cart"}
                                    onClick={() => addNewItemToCart(props)}
                                >
                                    Add to Cart  
                                    <i className="fa fa-chevron-right" />
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <FooterWhite />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAdd: (data) => {
            dispatch(addItemToCart(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSubs);
