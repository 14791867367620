import React, { useCallback, useEffect, useState } from "react";
// import dayjs from 'dayjs';
import { Row, Col } from "reactstrap";
// import {Alert, Container, } from "reactstrap";
import { toast, Zoom } from "react-toastify";
import { useSelector } from "react-redux";

// var utc = require('dayjs/plugin/utc')
// var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

const StoreClosed = () => {
    const settings = useSelector((state) => state.SettingsReducer);

    const ALERT_MSG =
        "The store is not accepting online orders at this time; however, the menu is avaiable to browse. PLEASE CHECK BACK LATER";

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setIsOpen(settings.storeOpen);
    }, [settings.storeOpen]);

    const update = () => setIsOpen(true);

    const Alert = () => {
        // updates state after one alert gets ran, dangerous to remove
        update();

        return toast.error(ALERT_MSG, {
            containerId: "B",
            transition: Zoom,
        });
    };

    return (
        <Row className="mt-3">
            <Col className="ml-auto mr-auto" md="10">
                <>
                    {isOpen ? (
                        // store is open
                        <></>
                    ) : (
                        // vis versa
                        // <Alert color="danger" id="exit-alert" style={style} isOpen={visible} toggle={onDismiss}>
                        //     The store is not accepting online orders at this time. You may still view the selection but you will not be able to place an order.
                        // </Alert>
                        <Alert />
                    )}
                </>
            </Col>
        </Row>
    );
};

export default StoreClosed;
