import React from "react";
// import Select from "react-select";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import dayjs from "dayjs";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
//import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import ContactUs from "./ContactUs.js";
import FooterWhite from "components/Footers/FooterWhite.js";

// custom components
// import TipModal from "../../components/TipModal/TipModal";
// import LoadingSpinner from "../../components/LoadingSpinner";

import {
  removeItemFromCart,
  updateItemCartQty,
} from "../../Reducers/Actions/userActions";

// import axios from "axios";
// import DateTimePicker from "react-datetime-picker";
// import cordialShoppeApi from "../../api/cordialShoppe";

// let { envs } = require("../../env");

function ConfirmationPage(props) {
  console.log("useParams:", useParams());
  const { tid, oid } = useParams();
  // const history = useHistory();
  console.log("tid:", tid);
  console.log("oid:", oid);

  // const [showLoading, setShowLoading] = useState(false);

  let state = {
    value: new Date().toISOString(),
  };

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  return (
    <>
      <DangerNavbar />
      <div className="wrapper">
        <div className="blog-2 section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h2 className="title">Order Confirmed!</h2>
                <p>Thank you for your order!</p>
                <p>Your confirmation number is {tid}.</p>
                <p>
                  <a href="/index">Click here to go back home.</a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <br />
      <ContactUs />
      <FooterWhite />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.UserReducer.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onCartClear: (data) => {
    //   dispatch(clearCart());
    // },
    onCartRemoveItem: (data) => {
      dispatch(removeItemFromCart(data));
    },
    onCartUpdateQty: (data) => {
      dispatch(updateItemCartQty(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
