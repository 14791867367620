import React, { useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Label,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";

// core components
//import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import ContactUs from "./ContactUs.js";
import FooterWhite from "components/Footers/FooterWhite.js";

// custom components
import TipModal from "../../components/TipModal/TipModal";
import LoadingSpinner from "../../components/LoadingSpinner";

import {
  removeItemFromCart,
  updateItemCartQty,
} from "../../Reducers/Actions/userActions";

import axios from "axios";

import DateTimePicker from "react-datetime-picker";

import cordialShoppeApi from "../../api/cordialShoppe";

let validOrderTime = true
//current_hour >= open_time && current_hour < close_time ? true : false;


let { envs } = require("../../env");

function OrderPage(props) {
  const getWaitTimeSetup = async () => {
    let response = await cordialShoppeApi.get("/settings");
    for (let setting of response.data) {
      if (setting.SettingID === "CMSWAITTIME") {
        return setting.Value;
      }
    }

    return false;
  };

  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const [tip, setTip] = useState({ percent: 0, custom: 0 });
  const [tipModal, setTipModal] = useState(false);

  const handleTipChange = (tip) => setTip(tip);
  const toggleTipModal = () => setTipModal(!tipModal);

  let state = {
    value: new Date().toISOString(),
  };

  const [orderTimeSelect, setOrderTimeSelect] = React.useState({
    value: "1",
    label: "ASAP",
  });

  let updateDate = (orderTimeSelect) => {
    setOrderTimeSelect(orderTimeSelect);

    if (orderTimeSelect.value === "2") {
      document.getElementById("hiddenDateTimePicker").style.display = "block";
    } else {
      document.getElementById("hiddenDateTimePicker").style.display = "none";
    }
  };

  const [dateSelect, setDateSelect] = React.useState(false);

  document.documentElement.classList.remove("nav-open");

  const submitForm = async (formTip = true) => {
    document.getElementById("submit_order_button").style.display = "none";
    document.getElementById("submit_order_button2").style.display = "none";

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //getWaitTimeSetup
    let wait_time = "20";

    let items = [];
    for (let i in props.cart) {
      items.push(props.cart[i]);
      if (props.cart[i].DeliWaitTime) {
        wait_time = props.cart[i].DeliWaitTime;
      }
    }

    let response = await getWaitTimeSetup();
    if (response && response > wait_time) {
      wait_time = response;
    }

    let customer_first_name = document.getElementById("orderFirstName").value;
    let customer_name =
      customer_first_name +
      " " +
      document.getElementById("orderLastName").value;

    let order_date;
    if (orderTimeSelect.label === "ASAP") {
      order_date = `ASAP - ${wait_time} Minutes`;
    } else {
      order_date = dayjs(dateSelect).format("MMMM D[,] [at] h:mm:ss A");
    }

    if (items.length > 0) {
      axios
        .post(
          envs.url + "/order",
          {
            Total: total,
            SubTotal: subtotal,
            Tax: tax,
            Tip: formTip ? tip : { percent: 0, custom: 0 },
            Items: items,
            Status: "New",
            Name: customer_name,
            Email: document.getElementById("orderEmail").value,
            Phone: document.getElementById("orderPhone").value,
            OrderDuration: orderTimeSelect,
            OrderDate: order_date,
            Wait: "",
            Notes: document.getElementById("orderNotes").value,
            Card: document.getElementById("orderCardNumber").value,
            Exp: document.getElementById("orderCardDate").value,
            Code: document.getElementById("orderCardCode").value,
          },
          headers
        )
        .then((res) => {
          console.log("res:", res);

          for (let i in props.cart) {
            props.onCartRemoveItem(props.cart[i]);
          }
          //   toast.success(`Order successfully placed. Thank you!`, {
          //     containerId: 'B',
          //     transition: Zoom,
          //   });
          setShowLoading(false);
          history.push(
            `/confirmation/${res.data.TransactionId}/${res.data.OrderId}`
          );
        })
        .catch((err) => {
          let res = err.response.data;
          toast.error(res.Message, {
            containerId: "B",
            transition: Zoom,
          });
          setShowLoading(false);
          setTipModal(false);

          document.getElementById("submit_order_button").style.display =
            "block";
          document.getElementById("submit_order_button2").style.display =
            "block";
        });
    } else {
      toast.error(`You have nothing in your cart!`, {
        containerId: "B",
        transition: Zoom,
      });
      setShowLoading(false);
      setTipModal(false);
    }

    // Loading Spinner
    setShowLoading(true);
  };

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  let realRows = [];
  let total = 0;
  let subtotal = 0;
  let itemCount = 0;

  if (props.cart.length < 1) {
    history.push("/cart");
  }

  for (let i in props.cart) {
    let item = props.cart[i];

    if (!item.Price) {
      item.Price = 100;
    }

    total += item.Price * item.Quantity;
    itemCount = parseInt(itemCount) + parseInt(item.Quantity);
  }

  let tax = total * 0.06;
  tax = tax.toFixed(2);

  subtotal = total;
  total = parseFloat(total) + parseFloat(tax);
  total = total.toFixed(2);

  return (
    <>
      <DangerNavbar />
      <div className="wrapper">
        <div className="blog-2 section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                {validOrderTime ? (
                  <>
                    <h4>
                      <a href="/menu">Back to Menu</a>
                    </h4>
                    <br />
                    <Row>
                      <Col md="12" style={{ textAlign: "center" }}>
                        <h2 className="title">Complete Your Order</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                        <h3 className="title">Your Details</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Label>First Name:</Label>
                        <Input id="orderFirstName" placeholder="John" />
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <Label>Last Name:</Label>
                        <Input id="orderLastName" placeholder="Smith" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Label>Your Email:</Label>
                        <Input
                          id="orderEmail"
                          placeholder="cordialshoppe1986@gmail.com"
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <Label>Phone Number:</Label>
                        <Input id="orderPhone" placeholder="313.291.1561" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                        <h3 className="title">Order Details</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Label>When do you want this order?</Label>
                        <FormGroup>
                          <Select
                            className="react-select react-select-default"
                            classNamePrefix="react-select"
                            name="orderTimeSelect"
                            value={orderTimeSelect}
                            onChange={(value) => updateDate(value)}
                            options={[
                              { label: "ASAP", value: "1" },
                              { label: "Future", value: "2" },
                            ]}
                            placeholder="Date"
                            id="orderDuration"
                          />

                          <div
                            id="hiddenDateTimePicker"
                            style={{ display: "none" }}
                          >
                            <br />
                            <Label>Select Date</Label>
                            <DateTimePicker
                              value={dateSelect}
                              onChange={setDateSelect}
                            // showTimeSelect
                            // timeFormat="HH:mm"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <textarea
                          className="form-control"
                          id="orderNotes"
                          rows="5"
                          placeholder="Notes...."
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                        <h3 className="title">Payment Details</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" md="4">
                        <Label>Card Number:</Label>
                        <Input
                          id="orderCardNumber"
                          placeholder="xxxx-xxxx-xxxx-xxxx"
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" md="4">
                        <Label>Expiration Date:</Label>
                        <Input id="orderCardDate" placeholder="03/23" />
                      </Col>
                      <Col className="ml-auto mr-auto" md="4">
                        <Label>Card Code:</Label>
                        <Input id="orderCardCode" placeholder="313" />
                      </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                      <Col className="ml-auto mr-auto" md="12">
                        <br />
                        <br />
                        <Button
                          color="success"
                          size="lg"
                          type="button"
                          className="btn-fill btn-round"
                          // onClick={() => submitForm()}
                          onClick={() => setTipModal(!tipModal)}
                        >
                          Complete Order
                          {/* <i className="fa fa-chevron-right" /> */}
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p>
                    <br />
                    Sorry, we are not taking online orders right now.
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <br />

      <FooterWhite />
      <TipModal
        modalIsOpen={tipModal}
        toggle={toggleTipModal}
        total={total}
        tipAction={handleTipChange}
        submitAction={submitForm}
        noThanksAction={() => {
          submitForm(false);
        }}
        loading={showLoading}
        setLoading={setShowLoading}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.UserReducer.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onCartClear: (data) => {
    //   dispatch(clearCart());
    // },
    onCartRemoveItem: (data) => {
      dispatch(removeItemFromCart(data));
    },
    onCartUpdateQty: (data) => {
      dispatch(updateItemCartQty(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
