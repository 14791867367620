import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Col } from "reactstrap";

function MenuSpecial() {
  return (
    <>
      <Col md="4">
        <a href="/menu-special" id="menu-page-special-menu-link" >
          <Card className="card-blog">
            <div className="card-image">
              <img
                alt="..."
                className="img img-raised"
                src={require("assets/img/sides3.jpg")}
                maxHeight="240"
              />
            </div>
            <CardBody>
              <CardTitle tag="h5">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                Specialty Items & Sides
                </a>
              </CardTitle>
              <p className="card-description">
                Click here for specialty items such as french fries, onion
                rings, and more! <br />
              </p>
            </CardBody>
          </Card>
        </a>
      </Col>
    </>
  );
}

export default MenuSpecial;
