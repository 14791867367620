import React from "react";
// plugin that creates slider
// import Slider from "nouislider";

import { Button, Form, Input, Container, Row, Col } from "reactstrap";
import { toast, Zoom } from "react-toastify";
import axios from "axios";

let {contact_env} = require('../../env');
let contact_url = contact_env.url;

const submitForm = () => {
  document.getElementById("submit_contact_button").style.display = "none";
  document.getElementById("sending_contact_button").style.display = "block";

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      contact_url,
      {
        Name: document.getElementById("Name").value,
        Email: document.getElementById("Email").value,
        Service: "Cordial Shoppe",
        Mobile: document.getElementById("Mobile").value,
        Message: document.getElementById("Message").value,
      },
      headers
    )
    .then((res) => {
      // document.getElementById("success_alert").style.display = "block";
      // document.getElementById("error_alert").style.display = "none";

      toast.success(`Message successfully sent. Thank you!`, {
        containerId: "B",
        transition: Zoom,
      });

      document.getElementById("submit_contact_button").style.display = "block";
      document.getElementById("sending_contact_button").style.display = "none";
    })
    .catch((err) => {
      // console.error(err);
      // document.getElementById("success_alert").style.display = "none";
      // document.getElementById("error_alert").style.display = "block";

      toast.error(`Contact error, we apologize, please call us at 313.291.1561!`, {
        containerId: "B",
        transition: Zoom,
      });

      document.getElementById("submit_contact_button").style.display = "block";
      document.getElementById("sending_contact_button").style.display = "none";
    });
};

function ContactUs() {
  return (
    <>
      <div className="section landing-section">
        <Container id="contact-section">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center">Contact Us</h2>

              <br />

              <Row>
                <Col xs="3" style={{ textAlign: "center" }}>
                  <a
                    class="btn-just-icon mr-1 btn btn-facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/cordialshoppe/"
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                </Col>
                <Col xs="3" style={{ textAlign: "center" }}>
                  <a
                    class="btn-just-icon btn btn-instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/thecordialshoppe/"
                  >
                    <i class="fa fa-instagram"></i>
                  </a>
                </Col>
                <Col xs="3" style={{ textAlign: "center" }}>
                  <a
                    class="btn-just-icon mr-1 btn btn-yelp"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.yelp.com/biz/the-cordial-shoppe-taylor"
                  >
                    <i class="fa fa-yelp"></i>
                  </a>
                </Col>
                <Col xs="3" style={{ textAlign: "center" }}>
                  <a
                    class="btn-just-icon mr-1 btn btn-youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/7LARlhI9G94"
                  >
                    <i class="fa fa-youtube"></i>{" "}
                  </a>
                </Col>
              </Row>
              <Form className="contact-form">
                <Row>
                  <Col md="4">
                    <label>Name</label>
                    <Input placeholder="Name" id="Name" />
                  </Col>
                  <Col md="4">
                    <label>Email</label>
                    <Input placeholder="Email" id="Email" />
                  </Col>
                  <Col md="4">
                    <label>Mobile</label>
                    <Input placeholder="Mobile" type="phone" id="Mobile" />
                  </Col>
                </Row>
                <label>Message</label>
                <Input
                  placeholder="Tell us your thoughts and feelings..."
                  type="textarea"
                  rows="4"
                  id="Message"
                />
                <Row>
                  <Col className="offset-md-4" md="12">
                    <Button
                      className="btn-fill"
                      color="danger"
                      size="lg"
                      id="submit_contact_button"
                      onClick={() => submitForm()}
                    >
                      <i className="nc-icon nc-email-85 mr-1" />
                      Send Message
                    </Button>
                    <Button
                      className="btn-round"
                      color="default"
                      style={{ display: "none" }}
                      id="sending_contact_button"
                    >
                      <div className="uil-reload-css reload-small mr-1">
                        <div />
                      </div>
                      Sending...
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <br />
          
          {/* <Row id="success_alert" style={{ display: "none" }}>
            <Col>
              <div class="alert alert-success fade show" role="alert">
                <div class="container">
                  <span>Message sent!</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row id="error_alert" style={{ display: "none" }}>
            <Col>
              <div
                class="alert-with-icon alert alert-danger fade show"
                role="alert"
              >
                <div class="container">
                  <div class="alert-wrapper">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i class="nc-icon nc-simple-remove"></i>
                    </button>
                    <div class="message">
                      <i class="nc-icon nc-bell-55"></i> Sorry there was an
                      error, please call us directly. (313.291.1561)
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}

export default ContactUs;
