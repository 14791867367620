import React from "react";
// plugin that creates slider
// import Slider from "nouislider";

// reactstrap components
import { Card, CardBody, CardTitle, Col } from "reactstrap";

function MenuSubs() {
  return (
    <>
      <Col md="4">
        <a href="/menu-chicken" id="menu-page-chicken-menu-link" >
          <Card className="card-blog">
            <div className="card-image">
              <img
                alt="..."
                className="img img-raised"
                src={require("assets/img/chicken.jpg")}
                maxHeight="240"
              />
            </div>
            <CardBody>
              <CardTitle tag="h5">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                Broasted Chicken
                </a>
              </CardTitle>
              <p className="card-description">
                Click here to order broasted chicken. <br />
              </p>
            </CardBody>
          </Card>
        </a>
      </Col>
    </>
  );
}

export default MenuSubs;
