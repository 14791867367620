// Reducer for Settings
import { ReactReduxContext } from "react-redux";

import { GET_HOURS, SET_HOURS, ADD_MESSAGE } from "./Actions/SettingsActions";

const settings_initalState = {
    Hours: {},
    ChosenDay: 0,
    Time: "00:00", // closing
    Time2: "00:00", // opening
    HoursReady: false,
    storeOpen: false,
};

const reducer = (state = settings_initalState, action) => {
    let { type, payload } = action;

    switch (type) {
        case "GET_HOURS":
            return {
                ...state,
                Hours: payload.Hours,
                HoursReady: payload.HoursReady,
            };
        case "SET_HOURS":
            // get the new times and save to state
            return {
                ...state,
                Hours: payload,
            };
        case "SET_CHOSEN_DAY":
            return { ...state, ChosenDay: payload };
        case "SET_HOURSREADY":
            return { ...state, HoursReady: payload };
        case "SET_TIME2":
            return { ...state, Time2: payload };
        case "SET_STOREOPEN":
            return { ...state, storeOpen: payload };
        case "ADD_MESSAGE":
            return { ...state, authMessage: payload };
        default:
            return state;
    }
};

export default reducer;
