import React, { useEffect } from 'react';
import {
  Container,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  FormText,
} from 'reactstrap';
import MultiDropdownNavbar from '../../components/Navbars/MultiDropdownNavbar';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { clearAuthMessage } from '../../Reducers/Actions/userActions';
import cordialShoppeApi from '../../api/cordialShoppe';
import { toast, Zoom } from "react-toastify";


const ForgotPasswordForm = ({ user, clearAuthMessage }) => {
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    clearAuthMessage();
  }, []);

  const getPasswordLink = async (data) => {
    const { Email } = data;
    cordialShoppeApi
      .post('/forgotpassword', { Email })
      .then(() => toast.success("Email sent!", {
        containerId: "B",
        transition: Zoom,
      }))
      .catch((err) => {
        console.error('Error:', err)
        toast.error(err.response.data.Message, {
          containerId: "B",
          transition: Zoom,
        })
      })
  };

  return (
    <div style={styles.flexContainer}>
      <MultiDropdownNavbar />
      <Container style={styles.formContainer}>
        <h1 style={styles.formTitle}>Forgot Password</h1>
        <h4>
          Enter your email below and check your email for a link to change your
          password
        </h4>
        <Form style={styles.form} onSubmit={handleSubmit(getPasswordLink)}>
          <FormGroup>
            <Label for="forgotpw-email">Email</Label>
            <Input
              type="email"
              name="Email"
              id="forgotpw-email"
              placeholder="name@email.com"
              innerRef={register({ required: true })}
            />
          </FormGroup>
          <Button type="submit" className="btn-fill" color="primary">
            Submit
          </Button>
          {user.authMessage ? (
            <p style={{ fontWeight: 'bold' }}>{user.authMessage}</p>
          ) : null}
          <FormText>
            If you don't have an account create one{' '}
            <a style={{ fontWeight: 'bold' }} href="signup">
              here
            </a>
          </FormText>
        </Form>
      </Container>
    </div>
  );
};

const styles = {
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(' +
      require('../../assets/img/bottle.png') +
      ')',
  },
  formContainer: {
    paddingBottom: '30px',
    borderRadius: '10px',
    maxWidth: '700px',
    backgroundColor: '#FFFFFF',
  },
  formTitle: {
    marginBottom: '30px',
  },
  form: {
    marginTop: '20px',
  },
};

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
};

export default connect(mapStateToProps, { clearAuthMessage })(
  ForgotPasswordForm
);
