// In the future, this will likely come from a DB?
let products = require("../../api/products");

module.exports = {
  getCateringItems: () => {
    return products.catering;
  },
  getChickenItems: () => {
    return products.chicken;
  },
  getSpecialtyItems: () => {
    return products.specialty;
  },
  getSaladTypes: () => {
    return products.saladTypes;
  },
  getDressingTypes: () => {
    return products.dressingTypes;
  },
  getStyleTypes: () => {
    return products.styleTypes;
  },
  getTypeTypes: () => {
    return products.typeTypes;
  },
};
