import React from "react";
import { connect } from "react-redux";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
    Button,
    Card,
    Carousel,
    CarouselIndicators,
    CarouselCaption,
    CarouselItem,
    FormGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import { toast, Zoom } from "react-toastify";
import { addItemToCart } from "../../Reducers/Actions/userActions";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar";
import MathTopping from "components/Menu/MathTopping";
import FooterWhite from "components/Footers/FooterWhite";

const shortid = require("shortid");

// carousel items
const carouselItems = [
    {
        src: require("assets/delipizza-small.png"),
        altText: "",
        caption: "",
    },
    // {
    //   src: require("assets/img/pizza.jpg"),
    //   altText: "",
    //   caption: "",
    // },
];

const pizzaOptions = [
    { value: "1", label: "Personal Deli Pizza" },
    { value: "2", label: "Small Deli Pizza" },
    { value: "3", label: "Medium Deli Pizza" },
    { value: "4", label: "Large Deli Pizza" },
];

function OrderDeliPizza(props) {
    const [topping1, setTopping1] = React.useState(3);
    const [topping2, setTopping2] = React.useState(3);
    const [pizzaTypes, setPizzaTypes] = React.useState([
        { value: "1", label: "Regular Italian" },
        { value: "2", label: "Turkey" },
        { value: "3", label: "Suicide Killer" },
    ]);

    const addNewItemToCart = (props) => {
        let pizza_name,
            pizza_desc = "";

        let item_price;
        if (colorSelect.value === "1") {
            // Personal Size
            if (meatSelect.label === "Turkey") {
                item_price = 0;
            } else if (meatSelect.label === "Suicide Killer") {
                item_price = 0;
            } else {
                item_price = 19.99;
            }
        }
        if (colorSelect.value === "2") {
            // Small Size
            if (meatSelect.label === "Turkey") {
                item_price = 45.99;
            } else if (meatSelect.label === "Suicide Killer") {
                item_price = 55.0;
            } else {
                item_price = 29.99;
            }
        }
        if (colorSelect.value === "3") {
            // Medium Size
            if (meatSelect.label === "Turkey") {
                item_price = 49.99;
            } else if (meatSelect.label === "Suicide Killer") {
                item_price = 59.99;
            } else {
                item_price = 34.99;
            }
        }
        if (colorSelect.value === "4") {
            // Large Size
            if (meatSelect.label === "Turkey") {
                item_price = 55.0;
            } else if (meatSelect.label === "Suicide Killer") {
                item_price = 65.0;
            } else {
                item_price = 39.99;
            }
        }

        if (colorSelect.value < 5) {
            let full_str = colorSelect.label;
            let str_parts = full_str.split("-");
            pizza_name = str_parts[0].trim();
            //pizza_name = colorSelect.label;

            if (topping1 !== 0) {
                if (pizza_desc !== "") {
                    pizza_desc = pizza_desc + ", ";
                }
                pizza_desc = pizza_desc + topping1;
                if (topping1.substr(0, 5) === "Extra") {
                    item_price += 5;
                }
            }
            if (topping2 !== 0) {
                if (pizza_desc !== "") {
                    pizza_desc = pizza_desc + ", ";
                }
                pizza_desc = pizza_desc + topping2;
                if (topping2.substr(0, 5) === "Extra") {
                    item_price += 10;
                }
            }

            if (pizza_desc === "") {
                pizza_desc = "Plain";
            }
        } else {
            let full_str = colorSelect.label;
            let str_parts = full_str.split("-");
            pizza_name = str_parts[0].trim();
            pizza_desc = str_parts[1].trim();
            item_price = colorSelect.price;
        }

        // Text Area Notes
        let notes_field = document.getElementById("deliNotes");
        if (notes_field.value !== "") {
            pizza_desc = pizza_desc + "\nCustomer Notes: " + notes_field.value;
        } else {
            //pizza_desc = pizza_desc + " | No customer notes.";
        }

        let cart_item = {
            ID: shortid.generate(),
            Name: pizza_name,
            Description: meatSelect.label + ", " + pizza_desc,
            Price: item_price,
            Quantity: quantitySelect.value,
            DeliWaitTime: 45,
        };
        console.log("cart_item:", cart_item);

        props.onItemAdd(cart_item);
        toast.success("Item added to cart!", {
            containerId: "B",
            transition: Zoom,
        });
    };

    const [colorSelect, setColorSelect] = React.useState({
        value: "0",
        label: "Select Pizza",
    });
    const [meatSelect, setMeatSelect] = React.useState({
        value: "1",
        label: " Regular Italian",
    });
    const [quantitySelect, setQuantitySelect] = React.useState({
        value: "1",
        label: "1",
    });

    const updateMeatDisplay = (value) => {
        setMeatSelect(value);
    };

    const updatePizzaDisplay = (value) => {
        setColorSelect(value);

        let addToCartDiv = document.getElementById("addToCartDiv");
        let addToppingsDiv = document.getElementById("addToppingsDiv");

        if (value.value < 5) {
            addToppingsDiv.style.display = "block";
        } else {
            addToppingsDiv.style.display = "none";
        }

        addToCartDiv.style.display = "block";
    };
    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const handlePizzaTypes = (num) => {
        //Check Size selected and set state with options
        if (num === "1") {
            setPizzaTypes([{ value: "1", label: "Regular Italian" }]);
        } else {
            setPizzaTypes([
                { value: "1", label: "Regular Italian" },
                { value: "2", label: "Turkey" },
                { value: "3", label: "Suicide Killer" },
            ]);
        }
        //Only Italian can have a personal size
        if (num === "1" && meatSelect.value !== "1") {
            setMeatSelect({
                value: "1",
                label: " Regular Italian",
            });
        }
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    });
    return (
        <>
            <DangerNavbar />
            {/* <ProductPageHeader /> */}
            <br />
            <div className="main">
                <div className="section">
                    <Container>
                        <Row className="title-row">
                            <Col md="12">
                                <h4 className="shop">
                                    <a href="/menu">Back to Menu</a>
                                </h4>
                            </Col>
                            {/* <Col className="ml-auto" md="4">
                <div className="pull-right">
                  <span className="text-muted">Order Status</span>
                  <Button color="link">
                    <i className="fa fa-shopping-cart" /> 0 Items
                  </Button>
                </div>
              </Col> */}
                        </Row>
                        <Row>
                            <Col md="7" sm="6">
                                <div className="ml-auto mr-auto" id="carousel">
                                    <Card className="page-carousel">
                                        <Carousel
                                            activeIndex={activeIndex}
                                            next={next}
                                            previous={previous}
                                        >
                                            <CarouselIndicators
                                                items={carouselItems}
                                                activeIndex={activeIndex}
                                                onClickHandler={goToIndex}
                                            />
                                            {carouselItems.map((item) => {
                                                return (
                                                    <CarouselItem
                                                        onExiting={onExiting}
                                                        onExited={onExited}
                                                        key={item.src}
                                                    >
                                                        <img
                                                            src={item.src}
                                                            alt={item.altText}
                                                        />
                                                        <CarouselCaption
                                                            captionText={
                                                                item.caption
                                                            }
                                                            captionHeader=""
                                                        />
                                                    </CarouselItem>
                                                );
                                            })}
                                            <a
                                                className="left carousel-control carousel-control-prev"
                                                data-slide="prev"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    previous();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-left" />
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </a>
                                            <a
                                                className="right carousel-control carousel-control-next"
                                                data-slide="next"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    next();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-right" />
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </a>
                                        </Carousel>
                                    </Card>
                                </div>
                                {/* end carousel */}

                                <table
                                    class="table"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Size</th>
                                            <th class="text-center">
                                                Italian Price
                                            </th>
                                            <th class="text-center">
                                                Turkey Price
                                            </th>
                                            <th class="text-center">
                                                Suicide Killer Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Personal</td>
                                            <td>$ 19.99</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>
                                        <tr>
                                            <td>Small</td>
                                            <td>$ 29.99</td>
                                            <td>$ 45.99</td>
                                            <td>$ 55.00</td>
                                        </tr>
                                        <tr>
                                            <td>Medium</td>
                                            <td>$ 34.99</td>
                                            <td>$ 49.99</td>
                                            <td>$ 59.99</td>
                                        </tr>
                                        <tr>
                                            <td>Large</td>
                                            <td>$ 39.99</td>
                                            <td>$ 55.00</td>
                                            <td>$ 65.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md="5" sm="6">
                                <h2>Deli Pizza</h2>
                                <hr />
                                <p>
                                    Our classic home-made deli pizzas are made
                                    fresh every day.{" "}
                                    <span style={{ color: "red" }}>
                                        Minimum wait time 45 minutes.
                                    </span>
                                </p>
                                <br />
                                <Row>
                                    <Col md="12" sm="12">
                                        {/* <label>Select starting pizza</label> */}
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="colorSelect"
                                                value={colorSelect}
                                                onChange={(value) => {
                                                    updatePizzaDisplay(value);
                                                    handlePizzaTypes(
                                                        value.value
                                                    );
                                                }}
                                                options={pizzaOptions}
                                                placeholder="COLOR"
                                            />
                                            <br />
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="meatSelect"
                                                value={meatSelect}
                                                onChange={(value) =>
                                                    updateMeatDisplay(value)
                                                }
                                                options={pizzaTypes}
                                                placeholder="COLOR"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row
                                    id="addToppingsDiv"
                                    style={{ display: "none" }}
                                >
                                    <Col>
                                        <label>Select Toppings</label>

                                        <FormGroup>
                                            <MathTopping
                                                data={{
                                                    label: "Cheese",
                                                    name: "Cheese",
                                                    value: topping1,
                                                    setValue: setTopping1,
                                                }}
                                            />
                                            <MathTopping
                                                data={{
                                                    label: "Meat",
                                                    name: "Meat",
                                                    value: topping2,
                                                    setValue: setTopping2,
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row
                                    id="addToCartDiv"
                                    style={{ display: "none" }}
                                >
                                    <hr />

                                    <Col md="12" sm="12">
                                        <label>Quantity</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="quantitySelect"
                                                value={quantitySelect}
                                                onChange={(value) =>
                                                    setQuantitySelect(value)
                                                }
                                                options={[
                                                    { value: "1", label: "1" },
                                                    { value: "2", label: "2" },
                                                    { value: "3", label: "3" },
                                                    { value: "4", label: "4" },
                                                    { value: "5", label: "5" },
                                                    { value: "6", label: "6" },
                                                    {
                                                        value: "7",
                                                        label: "7",
                                                    },
                                                    {
                                                        value: "8",
                                                        label: "8",
                                                    },
                                                    {
                                                        value: "9",
                                                        label: "9",
                                                    },
                                                    {
                                                        value: "10",
                                                        label: "10",
                                                    },
                                                ]}
                                                placeholder="QUANTITY"
                                            />

                                            <br />

                                            <textarea
                                                className="form-control"
                                                id="deliNotes"
                                                rows="5"
                                                placeholder="Notes...."
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col className="offset-md-5" md="7" sm="8">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="danger"
                                            onClick={() =>
                                                addNewItemToCart(props)
                                            }
                                        >
                                            Add to Cart  
                                            <i className="fa fa-chevron-right" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <FooterWhite />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAdd: (data) => {
            dispatch(addItemToCart(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDeliPizza);
