import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import ContactHome from "./ContactHome.js";
import cordialShoppeApi from "../../api/cordialShoppe";
import { useSelector } from "react-redux";

function HomePage(props) {
    document.documentElement.classList.remove("nav-open");
    const [CMS1, setCMS1] = useState(false);
    const [CMSHOURS1, setCMSHOURS1] = useState(false);
    const [CMSHOURS2, setCMSHOURS2] = useState(false);
    const [CMSHOURS3, setCMSHOURS3] = useState(false);
    const [onlineOrders, setOnlineOrders] = useState(false);

    const settings = useSelector((state) => state.SettingsReducer);

    const getSettingsSetup = async () => {
        try {
            let response = await cordialShoppeApi.get("/settings");
            for (let setting of response.data) {
                if (setting.SettingID === "OnlineOrders") {
                    setOnlineOrders(setting.Value);
                } else if (setting.SettingID === "CMS1") {
                    setCMS1(setting.Value);
                } else if (setting.SettingID === "CMSHOURS1") {
                    setCMSHOURS1(setting.Value);
                } else if (setting.SettingID === "CMSHOURS2") {
                    setCMSHOURS2(setting.Value);
                } else if (setting.SettingID === "CMSHOURS3") {
                    setCMSHOURS3(setting.Value);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        getSettingsSetup();
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <MultiDropdownNavbar />
            <LandingPageHeader data={{ OnlineOrders: onlineOrders }} />
            <div className="wrapper">
                <div className="section text-center landing-section">
                    <Container id="about-section">
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">City of Taylor Staple</h2>
                                <h3>{CMS1}</h3>
                                <br />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <div className="info">
                                    <div className="icon icon-danger">
                                        <i className="nc-icon nc-delivery-fast" />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">
                                            Hottest New Beverages
                                        </h4>
                                        <p className="description">
                                            Ever have difficulty finding these
                                            highly-allocated alcoholic
                                            beverages? Look no further than at
                                            The Cordial Shoppe. We strive our
                                            best to provide our loyal customers
                                            with an unmatched experience from
                                            beginning to end from the moment
                                            they walk in, while also
                                            differentiating ourselves by
                                            offering unique one-of-a-kind items.
                                        </p>
                                        <Button
                                            className="btn-link"
                                            color="danger"
                                            href="tel:+13132911561"
                                        >
                                            Give us a call!
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="info">
                                    <div className="icon icon-danger">
                                        <i className="nc-icon nc-tag-content" />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">
                                            Award-Winning Culinary
                                        </h4>
                                        <p>
                                            There is no other store which can
                                            get your taste buds rolling like
                                            here at The Cordial Shoppe. We have
                                            been recognized multiple times for
                                            our outstanding culinary experience
                                            we offer here, but do not only take
                                            our word for it. See what{" "}
                                            <a href="https://www.mlive.com/dining/2017/05/michigans_best_sub_sandwich_a.html">
                                                MLIVE.com
                                            </a>{" "}
                                            said about us. Give us a call today!
                                            Believe me you will not regret it!
                                        </p>
                                        <Button
                                            className="btn-link"
                                            color="danger"
                                            href="tel:+13132911550"
                                        >
                                            Give us a call!
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <div className="info">
                                    <div className="icon icon-danger">
                                        <i className="nc-icon nc-watch-time" />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">
                                            Hours of Operation
                                        </h4>
                                        <h5>
                                            {CMSHOURS1}
                                            <br />
                                            {CMSHOURS2}
                                            <br />
                                            {CMSHOURS3}
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {onlineOrders && settings.storeOpen ? (
                                    <Button color="danger" href="/menu">
                                        <i className="nc-icon nc-bag-16 mr-1" />
                                        Order Now
                                    </Button>
                                ) : (
                                    <p>
                                        Sorry, we are not taking online orders
                                        right now.
                                    </p>
                                )}
                                <br />
                                {/* <br />
                <Button
                  className="btn-fill btn-round"
                  color="success"
                  type="button"
                  href="https://cordail-shoppe-s3.s3.us-east-2.amazonaws.com/menu1.pdf"
                  //target="_blank"
                >
                  <i className="nc-icon nc-paper mr-1" />
                  See Menu Page 1
                </Button>
                &nbsp;
                <Button
                  className="btn-fill btn-round"
                  color="success"
                  type="button"
                  href="https://cordail-shoppe-s3.s3.us-east-2.amazonaws.com/menu2.pdf"
                  //target="_blank"
                >
                  <i className="nc-icon nc-paper mr-1" />
                  See Menu Page 2
                </Button> */}
                            </Col>
                        </Row>
                        <br />
                    </Container>
                </div>

                <ContactHome />
            </div>
            <FooterWhite />
        </>
    );
}

export default HomePage;
