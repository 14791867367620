let envs;
let contact_env;

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'prod') {
  envs = {
    url: 'https://vhl9u77oxj.execute-api.us-east-2.amazonaws.com/prod'
  }
  contact_env = {
    url: 'https://km5vdfidod.execute-api.us-east-2.amazonaws.com/prod/contact',
  }
}
else if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'beta') {
  envs = {
    url: 'https://azvj061hii.execute-api.us-east-2.amazonaws.com/beta'
  }
  contact_env = {
    url: '	https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact',
  }
}
else {
  envs = {
    url: 'https://ghy811agrb.execute-api.us-east-2.amazonaws.com/dev'
  }
  contact_env = {
    url: 'https://e43pq91ima.execute-api.us-east-2.amazonaws.com/dev/contact',
  }
}

module.exports = {
  envs, 
  contact_env
};