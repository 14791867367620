/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// Redux
import store from "./store";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
    getHours,
    setHours,
    setStoreOpen,
} from "./Reducers/Actions/SettingsActions";
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import { ToastContainer } from "react-toastify";

// Cordail
import HomePage from "views/cordail/HomePage.js";
import MenuPage from "views/cordail/MenuPage.js";
import MenuPagePizza from "views/cordail/MenuPagePizza.js";
import MenuPageDeliPizza from "views/cordail/MenuPageDeliPizza.js";
import MenuPageSalad from "views/cordail/MenuPageSalad.js";
import MenuPageSubs from "views/cordail/MenuPageSubs.js";
import MenuPageSpecial from "views/cordail/MenuPageSpecial.js";
import MenuPageChicken from "views/cordail/MenuPageChicken.js";
import MenuPageCatering from "views/cordail/MenuPageCatering.js";
import Signin from "views/cordail/Signin";
import Signup from "views/cordail/Signup";
import CartPage from "views/cordail/CartPage.js";
import OrderPage from "views/cordail/OrderPage.js";
import ConfirmationPage from "views/cordail/ConfirmationPage.js";
import Dash from "views/cordail/Dash.js";
import DashOrders from "views/cordail/DashOrders.js";
import DashUsers from "views/cordail/DashUsers.js";
import DashContacts from "views/cordail/DashContacts.js";
import ForgotPasswordForm from "views/cordail/ForgotPasswordForm";
import ChangePassword from "views/cordail/ChangePassword";
import { Hours, calcTime } from "./api/hours";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

const App = () => {
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.SettingsReducer);
    const acc = useSelector((state) => state.AccountReducer);

    const [isOpen, setIsOpen] = useState(true);

    const timeDiff = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);

        return (
            (hours < 0 ? "-" : "+") +
            ((hours < 0 ? hours * -1 : hours) <= 9 ? "0" : "") +
            (hours < 0 ? hours * -1 : hours) +
            ":" +
            (minutes < 9 ? "0" : "") +
            minutes
        );
    };

    const areWeOpen = (timeNow, dayObject) => {
        // calculates if we are open or not
        // expected dayObject = {Open: '10:00', Close: '22:00'}
        // expected timeNow = '10:00'

        if (!dayObject) {
            console.log("dayObj undefined, time error");
            return false;
        }

        let deltaStartTime = timeDiff(dayObject.Open, timeNow); // example output: -08:21, 05:32
        let deltaEndTime = timeDiff(timeNow, dayObject.Close); // example output: -08:21, 05:32

        // current time is after open time
        if (deltaStartTime[0] === "-") {
            console.log(
                "Before Start Time",
                deltaStartTime,
                deltaEndTime,
                timeNow,
                dayObject.Open
            );
            return false;
        }
        // current time is before close time
        else if (deltaEndTime[0] === "-") {
            console.log(
                "After End Time",
                deltaEndTime,
                timeNow,
                dayObject.Close
            );
            return false;
        }

        console.log("OPEN NOW", deltaStartTime, deltaEndTime, timeNow);
        return true;
    };

    useEffect(() => {
        dispatch(getHours());
    }, []);

    const MINUTE_MS = 60000;

    useEffect(() => {
        if (settings.HoursReady) {
            const interval = setInterval(() => {
                const now = dayjs().tz("America/Detroit"); // apply timezone and get current time and day
                const day = now.format("dddd"); // day of the week, in order to get today's hours
                const time = now.format("HH:mm"); // get time in certain format today (08:20)
                const dayObj = settings.Hours[day];

                dispatch(setStoreOpen(areWeOpen(time, dayObj)));
            }, MINUTE_MS);

            const now = dayjs().tz("America/Detroit"); // apply timezone and get current time and day
            const day = now.format("dddd"); // day of the week, in order to get today's hours
            const time = now.format("HH:mm"); // get time in certain format today (08:20)
            const dayObj = settings.Hours[day];

            console.log(timeDiff(time, "03:00"));

            dispatch(setStoreOpen(areWeOpen(time, dayObj)));

            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [settings.HoursReady, settings.Hours]);

    useEffect(() => {
        const now = dayjs().tz("America/Detroit"); // apply timezone and get current time and day
        const day = now.format("dddd"); // day of the week, in order to get today's hours
        const time = now.format("HH:mm"); // get time in certain format today (08:20)
        const dayObj = settings.Hours[day];

        console.log(timeDiff(time, "03:00"));

        dispatch(setStoreOpen(areWeOpen(time, dayObj)));
    }, [settings.Hours]);

    return (
        <Switch>
            <Route path="/index" render={(props) => <HomePage {...props} />} />

            <Route path="/menu" render={(props) => <MenuPage {...props} />} />
            <Route
                path="/menu-pizza"
                render={(props) => <MenuPagePizza {...props} />}
            />
            <Route
                path="/menu-subs"
                render={(props) => <MenuPageSubs {...props} />}
            />
            <Route
                path="/menu-deli-pizza"
                render={(props) => <MenuPageDeliPizza {...props} />}
            />
            <Route
                path="/menu-salad"
                render={(props) => <MenuPageSalad {...props} />}
            />
            <Route
                path="/menu-special"
                render={(props) => <MenuPageSpecial {...props} />}
            />
            <Route
                path="/menu-chicken"
                render={(props) => <MenuPageChicken {...props} />}
            />
            <Route
                path="/menu-catering"
                render={(props) => <MenuPageCatering {...props} />}
            />
            <Route path="/signin" render={(props) => <Signin {...props} />} />
            <Route path="/signup" render={(props) => <Signup {...props} />} />
            <Route
                path="/forgotpw"
                render={(props) => <ForgotPasswordForm {...props} />}
            />
            <Route
                path="/changepw/email=:email&token=:token"
                render={(props) => <ChangePassword {...props} />}
            />
            <Route path="/cart" render={(props) => <CartPage {...props} />} />
            <Route path="/order" render={(props) => <OrderPage {...props} />} />
            <Route
                path="/confirmation/:tid/:oid"
                render={(props) => <ConfirmationPage {...props} />}
            />

            <Route exact path="/dash" render={(props) => <Dash {...props} />} />
            <Route
                path="/dash/orders"
                render={(props) => <DashOrders {...props} />}
            />
            <Route
                path="/dash/users"
                render={(props) => <DashUsers {...props} />}
            />
            <Route
                path="/dash/contacts"
                render={(props) => <DashContacts {...props} />}
            />

            <Redirect to="/index" />
        </Switch>
    );
};

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
