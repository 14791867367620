import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { connect } from "react-redux";
// reactstrap components
import { Button, FormGroup, Row, Col } from "reactstrap";
import { addItemToCart } from "../../Reducers/Actions/userActions";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const shortid = require("shortid");

function Item(props) {
    const cid = Math.floor(Math.random() * Math.floor(1000000));
    props.data.cid = cid;

    const [colorSelect, setColorSelect] = React.useState({
        value: "1",
        label: props.data.items ? props.data.items[0].label : "",
        price: props.data.items
            ? props.data.items[0].price
            : props.data.price
            ? props.data.price
            : 147,
    });

    const addNewItemToCart = (props) => {
        let item = props.data;
        document.getElementById("add_to_cart_" + item.cid).style.display =
            "none";
        document.getElementById("sending_to_cart_" + item.cid).style.display =
            "block";

        let item_price = colorSelect.price
            ? colorSelect.price
            : props.data.price
            ? props.data.price
            : 147;

        let notes_str = "";
        let notes_field = document.getElementById("itemNote");
        if (notes_field && notes_field.value) {
            notes_str = " " + notes_field.value;
        }

        let cart_item = {
            ID: shortid.generate(),
            Name: item.title,
            Description: colorSelect.label + notes_str,
            Price: item_price,
            Quantity: 1,
            //DeliWaitTime: 45,
            //Image: "assets/img/pizza.jpg",
        };
        console.log("prop data:", props.data);

        if (props.data.wait) {
            cart_item.DeliWaitTime = props.data.wait;
        }
        console.log("cart_item:", cart_item);

        props.onItemAdd(cart_item);
        toast.success("Item Successfully added!", {
            containerId: "B",
            transition: Zoom,
        });

        document.getElementById("add_to_cart_" + item.cid).style.display =
            "block";
        document.getElementById("sending_to_cart_" + item.cid).style.display =
            "none";
    };

    let disp_select = "";
    let disp_notes = "";
    let sub_note = props.data.note;
    if (props.data.items && props.data.items.length > 1) {
        disp_select = (
            <FormGroup>
                <Select
                    className="react-select react-select-default"
                    classNamePrefix="react-select"
                    name="colorSelect"
                    value={colorSelect}
                    onChange={(value) => setColorSelect(value)}
                    options={props.data.items}
                    placeholder={props.data.title}
                />
            </FormGroup>
        );
    } else {
        let price_display = props.data.note
            ? props.data.note
            : props.data.price
            ? "$" + props.data.price
            : props.data.items[0].price
            ? "$" + props.data.items[0].price
            : "";

        sub_note = price_display;
    }

    if (props.data.notes) {
        disp_notes = (
            <FormGroup>
                <textarea
                    className="form-control"
                    id="itemNote"
                    rows="5"
                    placeholder="Item Notes...."
                />
            </FormGroup>
        );
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <Row>
                <Col md="12" sm="12">
                    <label style={{ fontWeight: "bold" }}>
                        {props.data.title}
                    </label>
                    <p>{sub_note}</p>

                    {disp_select}
                    {disp_notes}
                </Col>
            </Row>
            <Row>
                <Col md="6" sm="6">
                    <Button
                        block
                        className="btn-round"
                        color="danger"
                        id={"add_to_cart_" + cid}
                        onClick={() => addNewItemToCart(props)}
                    >
                        Add to Cart  <i className="fa fa-chevron-right" />
                    </Button>
                    <Button
                        className="btn-round"
                        color="default"
                        style={{ display: "none" }}
                        id={"sending_to_cart_" + cid}
                    >
                        <div className="uil-reload-css reload-small mr-1">
                            <div />
                        </div>
                        Adding...
                    </Button>
                </Col>
            </Row>
            <hr />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.UserReducer.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAdd: (data) => {
            dispatch(addItemToCart(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Item);
