import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';
import MultiDropdownNavbar from '../../components/Navbars/MultiDropdownNavbar';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  signin,
  clearAuthMessage,
  changePassword,
} from '../../Reducers/Actions/userActions';

const ChangePassword = ({ user, clearAuthMessage, changePassword }) => {
  const { register, handleSubmit, errors } = useForm();
  const { email } = useParams();
  const history = useHistory();

  useEffect(() => {
    clearAuthMessage();
  }, []);

  const changePass = (data) =>
    changePassword({ ...data, Email: email }, () => history.push('/signin'));

  return (
    <div style={styles.flexContainer}>
      <MultiDropdownNavbar />
      <Container style={styles.formContainer}>
        <h2 style={styles.formTitle}>Change Password</h2>
        <p style={{ fontSize: '1.3em', marginLeft: '15px' }}>{email}</p>
        <Form style={styles.form} onSubmit={handleSubmit(changePass)}>
          <Col>
            <FormGroup>
              <Label for="new-password">New Password</Label>
              <Input
                type="password"
                name="NewPassword"
                id="new-password"
                placeholder="********"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="confirm-password">Confirm New Password</Label>
              <Input
                type="password"
                name="ConfirmPassword"
                id="confirm-password"
                placeholder="********"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            {user.authMessage ? (
              <p style={{ fontWeight: 'bold' }}>{user.authMessage}</p>
            ) : null}
          </Col>
          <Button type="submit" className="btn-fill" color="primary">
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
};

const styles = {
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(' +
      require('../../assets/img/bottle.png') +
      ')',
  },
  formContainer: {
    paddingBottom: '30px',
    borderRadius: '10px',
    maxWidth: '700px',
    backgroundColor: '#FFFFFF',
  },
  formTitle: {
    marginBottom: '30px',
  },
  form: {},
};

const mapStateToProps = (state) => {
  return { user: state.AccountReducer };
};

export default connect(mapStateToProps, {
  signin,
  clearAuthMessage,
  changePassword,
})(ChangePassword);
